// import { prefixPath } from '@/config'
import ButtonUpload from '@/components/button-upload'
import { timeFormat } from '@/utils'
export default function (index) {
  const config = this.dynamicList[index]
  if (!config) return []
  // let tireTreeMap = ['individualStatus']
  const list = config.formPropertyList.map(item => {
    if (item.propertyConfig && item.propertyConfig.indexOf('session_listOrgId') > -1) {
      item.propertyConfig = item.propertyConfig.replace('session_listOrgId', this.orgId)
    }
    if (item.propertyConfig && item.propertyConfig.indexOf('prefixPath') > -1) {
      item.propertyConfig = item.propertyConfig.replace('prefixPath', 'api-uum/')
    }
    const props = JSON.parse(item.propertyConfig || '{}')
    if (item.widgetType === 'fileUpload') {
      props.data = props.data || {}
      props.data.groupId = this.groupId
      props.isShowFileList = true
    }
    if (item.widgetType === 'InputNumber') {
      props.min = 0
    }
    if (item.widgetType === 'selectInputMember') {
      props.bizLineCode = props.bizLineCode || this.bizLineCode
      props.isAllResult = props.isAllResult || true
    }
    if (item.widgetType === 'universalTree' && props.treeParams) {
      if (props.treeParams.orgId === '') {
        props.treeParams.orgId = -1
        sessionStorage.setItem('orgId', -1)
      }
      props.treeParams.bizLineCode = props.treeParams.bizLineCode || this.bizLineCode
    }
    if (item.propertyCode === 'userName') {
      item.isReadonly = 1
    }
    //码表接口前缀
    if (props.treeType == 'tireTree') {
      if (props.url && props.url.indexOf('api-general') < 0 && props.params && props.params.dictCode) {
        props.url = 'api-general/module/portal/user/outGetRadioList'
        props.params.bizLineCode = this.bizLineCode
        sessionStorage.setItem('orgId', -1)
      }
    } else {
      if (props.url && props.url.indexOf('api-general') < 0 && props.params && props.params.dictCode) {
        props.url = 'api-general/module/portal/user/outGetList'
        props.params.bizLineCode = this.bizLineCode
      }
    }
    if (item.description) {
      props.extra = true
    }
    if (config.groupType == 1) { //表单
      if (item.widgetType == 'Input') {
        if (!props.maxlength) {
          props.maxlength = 500
        }
      }
      if (item.propertyCode == 'userName') {
        item.isNotNull = 2
      }
      if (item.widgetType == 'textarea') {
        if (!props.maxlength) {
          props.maxlength = 5000
        }
      }
      if (item.widgetType == 'DatePicker') {
        props.option = item.option
      }
      return item.isEnabled == 1 ? {
        type: item.widgetType || 'Input',
        title: item.displayName || item.propertyName,
        [$field]: item.propertyCode,
        colSpan: item.colspan || 8,
        description: item.description || '',
        readOnly: item.isReadonly == 1 ? true : false,
        props: props,
        rules: item.isNotNull == 1 ? ['required'] : []
      } : null
    } else { //表格
      return item.isEnabled == 1 && item.widgetType != 'hidden' ? {
        showType: item.widgetType || '',
        title: item.displayName || item.propertyName,
        [$field]: item.propertyCode,
        width: item.width || null,
        minWidth: item.minWidth || null,
        readOnly: item.isReadonly == 1 ? true : false,
        props: props,
        render: (h, { row }) => {
          if (item.propertyCode == 'configName') {
            if (row.isNotNull == 1) {
              return (<div>
                <span style="marginRight: '2px';color: red">*</span>
                <span>{row.configName}</span>
              </div>)
            } else {
              return h('span', row[item.propertyCode])
            }
          } else if (item.propertyCode == 'fileName') {
            return h('span', {
              class: 'iv-pointer iv-hover-color iv-main-color',
              on: {
                click: () => {
                  let url = `api-uum/file/downloadFile?fileId=${row.fileId}` + '&access_token=' + sessionStorage.getItem('token') + '&currentAuthScopeId=' + sessionStorage.getItem('listOrgId')
                  window.open(url)
                }
              }
            }, row.fileName)
          } else {
            return h('span', row[item.propertyCode])
          }
        }
      } : null
    }
  }).filter(v => v)
  if (config.groupCode == 'bisFileList') {
    list.push({
      title: '操作',
      [$field]: 'operate',
      width: 200,
      isOperate: true,
      render: (h, { row }) => {
        return h('div',
          [
            h(ButtonUpload, {
              props: {
                label: '附件上传',
                isText: true,
                url: 'api-uum/file/upload',
                accept: row.props?.accept || '.pdf,.jpg,.jpeg,.png,.gif'
              },
              style: {
                'padding': '0',
                'line-height': '20px',
                'margin-top': '8px',
                'display': 'inline-block'
              },
              class: ['iv-main-color', 'iv-hover-color'],
              on: {
                'on-upload-success': (file) => {
                  if (!this.uploadFileList[row.propertyCode]) {
                    this.uploadFileList[row.propertyCode] = []
                  }
                  this.formItem.bisFileList.map(item => {
                    if (item.propertyCode == row.propertyCode) {
                      item.fileUpDate = timeFormat(file.response.data.uploadTime)
                      item.isYsc = '已上传'
                      item.fileId = file.response.data.fileId
                      item.fileName = file.response.data.fileName
                    }
                  })
                  this.uploadFileList[row.propertyCode] = [{
                    fileDefineId: file.response.data.fileDefineId,
                    fileExt: file.response.data.fileExt,
                    fileId: file.response.data.fileId,
                    fileName: file.response.data.fileName,
                    filesize: file.size,
                    groupId: file.response.data.groupId,
                    mimeType: file.response.data.mimeType,
                    uploadTime: file.response.data.uploadTime,
                    uploaduser: file.response.data.uploaduser
                  }]
                }
              }
            }), h('span', {
              style: {
                'padding': '0',
                'line-height': '20px',
                'padding-left': '10px',
                'margin-top': '8px',
                'display': 'inline-block',
                'position': 'absolute',
                'top': '58px',
                'cursor': 'pointer'
              },
              class: ['iv-main-color', 'iv-hover-color'],
              on: {
                'click': () => {
                  this.formItem.bisFileList.map(item => {
                    if (item.propertyCode == row.propertyCode) {
                      this.downTemp('down', item)
                    }
                  })
                  // this.downTemp('see')
                }
              }
            }, '下载模板')
          ])
      }
    })
  }
  return config.isEnabled == 1 ? list : []
}