// import { prefixPath } from '@/config'
// import ButtonUpload from '@/components/button-upload'
// import { timeFormat } from '@/utils'
export default function (value, index, _index) {
  let config
  if (value.groupCode !== 'page01') {
    config = value
  } else {
    config = this.dynamicList[index]
  }
  if (!config) return []
  // let tireTreeMap = ['individualStatus']
  const list = config.formPropertyList.map(item => {
    if (item.propertyConfig && item.propertyConfig.indexOf('session_listOrgId') > -1) {
      item.propertyConfig = item.propertyConfig.replace('session_listOrgId', this.orgId)
    }
    if (item.propertyConfig && item.propertyConfig.indexOf('prefixPath') > -1) {
      item.propertyConfig = item.propertyConfig.replace('prefixPath', 'api-uum/')
    }
    const props = JSON.parse(item.propertyConfig || '{}')
    if (item.widgetType === 'fileUpload') {
      props.data = props.data || {}
      props.data.groupId = this.groupId
      props.isShowFileList = true
    }
    if (item.widgetType === 'InputNumber') {
      props.min = 0
    }
    if (item.widgetType === 'selectInputMember') {
      props.bizLineCode = props.bizLineCode || this.bizLineCode
      props.isAllResult = props.isAllResult || true
    }
    if (item.widgetType === 'universalTree' && props.treeParams) {
      if (props.treeParams.orgId === '') {
        props.treeParams.orgId = -1
      }
      props.treeParams.bizLineCode = props.treeParams.bizLineCode || this.bizLineCode
    }
    //码表接口前缀
    if (props.treeType == 'tireTree') {
      if (props.url && props.url.indexOf('api-general') < 0 && props.params && props.params.dictCode) {
        props.url = 'api-general/module/portal/user/outGetRadioList'
        props.params.bizLineCode = this.bizLineCode
      }
    } else {
      if (props.url && props.url.indexOf('api-general') < 0 && props.params && props.params.dictCode) {
        props.url = 'api-general/module/portal/user/outGetList'
        props.params.bizLineCode = this.bizLineCode
      }
    }
    if (item.description) {
      props.extra = true
    }
    if (config.groupType == 1) { //表单
      if (item.widgetType == 'Input') {
        if (!props.maxlength) {
          props.maxlength = 500
        }
      }
      if (item.propertyCode == 'userName') {
        item.isNotNull = 2
      }
      if (item.widgetType == 'textarea') {
        if (!props.maxlength) {
          props.maxlength = 5000
        }
      }
      if (item.propertyCode === 'userName') {
        item.isReadonly = 1
      }
      if (item.propertyCode == 'inspectTime' || item.propertyCode == '05FZDY2102') {
        item.isReadonly = 1 
        item.isNotNull = 2
      }
      return item.isEnabled == 1 ? {
        type: item.widgetType || 'Input',
        title: item.displayName || item.propertyName,
        [$field]: item.propertyCode,
        colSpan: item.colspan || 8,
        description: item.description || '',
        readOnly: item.isReadonly == 1 ? true : false,
        props: props,
        optionList: props.optionList || [],
        rules: item.isNotNull == 1 ? ['required'] : []
      } : null
    } else { //表格
      return item.isEnabled == 1 && item.widgetType != 'hidden' ? {
        showType: item.widgetType || '',
        title: item.displayName || item.propertyName,
        [$field]: item.propertyCode,
        width: item.width || null,
        minWidth: item.minWidth || null,
        readOnly: item.isReadonly == 1 ? true : false,
        props: props,
        render: (h, { row }) => {
          if (item.propertyCode == '05FZDY2103') {
            let fileId, fileName
            if (row[item.propertyCode] && row[item.propertyCode][0]) {
              fileId = row[item.propertyCode][0]['fileId']
              fileName = row[item.propertyCode][0]['fileName']
            }
            return h('span', {
              style: {
                'padding': '0',
                'line-height': '20px',
                'display': 'inline-block',
                'cursor': 'pointer'
              },
              class: ['iv-main-color', 'iv-hover-color'],
              on: {
                'click': () => {
                  // let fileId = this.uploadFileList['05FZDY2103'].fileId
                  let url = `api-uum/file/downloadFile?fileId=${fileId}` + '&access_token=' + sessionStorage.getItem('token') + '&currentAuthScopeId=' + sessionStorage.getItem('listOrgId')
                  window.open(url)
                }
              }
            }, fileName)
          } else {
            return h('span', row[item.propertyCode])
          }
        }
      } : null
    }
  }).filter(v => v)
  return config.isEnabled == 1 ? list : []
}