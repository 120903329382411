<template>
  <div>
    <span
      v-if="item.typeName != '培养教育意见'"
      class="iv-main-color iv-main-hover-color iv-operate-item iv-pull-left iv-pointer uit-editor-list"
      @click="expandParent('see', item)"
    >{{'查看'}}</span>
    <span 
      v-if="type != 'see' && item.typeCode != '3' && item.typeName != '培养教育意见'"
      class="iv-main-color iv-main-hover-color iv-operate-item iv-pull-left iv-pointer uit-editor-list"
      @click="expandParent('edit', item)"
    >{{'修改'}}</span>
    <Poptip
      v-if="type != 'see' && item.typeCode != '3' && item.typeName != '培养教育意见'"
      confirm
      transfer
      class="iv-pull-left iv-main-color iv-main-hover-color iv-operate-item iv-pointer uit-delete-list"
      :title="'是否确定删除？'"
      @on-ok="expandParent('delete', item)">
      <span>
        删除
      </span>
    </Poptip>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  props: ['item'],
  computed: {
    ...mapGetters(['scope'])
  },
  data () {
    return {
      type: this.$route.query.type
    }
  },
  inject: {
    expandParent: {
      default () {
        return function () {
          console.log('')
        }
      }
    }
  }
}
</script>
