<template>
  <div style="background: #fff;height: 100%;margin-top: -16px;">
    <div class="iv-main-width  iv-p-b-40 iv-p-t-40">
      <!-- <h1 class="iv-fs-24 iv-fc-85 iv-vertical-line">{{ infoMation.stepDescription }}</h1> -->
    <div class="iv-main-width">
      <template v-for="(item, index) in pageafterList">
        <components
          :key="index"
          :infoMation="item"
          :readOnly="true"
          :is="item.stepFormState.formCode">
        </components>
      </template>
      <LayoutTitle :title="item.groupName" v-for="(item, index) in dynamicList" :key="item.groupId">
      <FormLayout
        v-if="item.formPropertyList.length && item.groupType == 1"
        ref="eleventhStep" 
        :readOnly="readOnly"
        :model="formItem[item.groupCode]"
        :list="generatePage(index)"
        @on-selectree-change="onSelectreeChange"
        @on-change-tree="onChangeTreeValue"
        >
      </FormLayout>
      <div class="iv-text-right" v-if="item.groupCode == 'page03'">
        <Button type="add" class="uit-save iv-pointer iv-m-t-16" @click="onAddPeople">
          <span>添加培训记录</span>
        </Button>
      </div>
      <DragTable
        class="iv-m-t-24"
        noSearch
        v-if="item.groupType != 1"
        :showEdit="false"
        :showDelete="false"
        :showSelect="false"
        :showPage="false"
        ref="DragTable" 
        :immediateForce="true" 
        :columns="generatePage(index)" 
        :tableData="formItem[item.groupCode]">
      </DragTable>
    </LayoutTitle>
    <FooterOperator>
      <Button class="iv-m-r-8"  type="submit" @click="click" v-if="infoMation.active">提交</Button>
      <Button class="iv-m-r-8"  type="save" @click="save" v-else>保存</Button>
      <Button class="iv-m-r-8"  type="back" @click="back">返回</Button>
    </FooterOperator>
    </div>
  </div>
  </div>
</template>

<script>
import FormLayout from '@/components/layout/form'
import LayoutTitle from '@/components/layout/layout-title'
import eleventh from './configs/eleventh'
import FooterOperator from '@/components/footer-operator'
import { timeFormat } from '@/utils'
import DragTable from '@/components/drag-table-inline'
// import { prefixPath } from '@/config'
import Schema from 'async-validator'
import importComponents from './combination'
export default {
  components: {
    DragTable,
    FormLayout,
    LayoutTitle,
    FooterOperator,
    importComponents
  },
  props: {
    infoMation: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      formItem: {
        page02: {},
        page03: []
      },
      dynamicList: [],
      readOnly: false,
      version: this.$route.query.version,
      formId: this.$route.query.formId,
      orgId: sessionStorage.getItem('orgId'),
      bizLineCode: 'YWX01',
      pmdUserId: this.$route.query.pmdUserId, //YWX01
      bisFileList: [],
      configFileList: [],
      uploadFileList: {},
      orgInfo: {},
      content: this.$route.query.title,
      dataDictionary: {},
      tableRules: {}
    }
  },
  beforeCreate () {
    this.$options.propsData.infoMation.beforeStepFormStates.map(item => {
      this.$options.components[item.formCode] = importComponents[item.formCode]
    })
  },
  created () {
    this.formId = this.infoMation.stepFormState.formId
    this.version = this.infoMation.stepFormState.formVersion
    this.pageafterList = this.changeList(this.infoMation.beforeStepFormStates || [])
    Promise.all([
      this.getCodeTable('GG73'),
      this.getCodeTable('GG74')
    ]).then(() => {
      this.getDynamicFormInfo()
    })
  },
  methods: {
    changeList (list) {
      let formDataList = []
      list.map(item => {
        item.noShowList = true
        formDataList.push({
          stepFormState: item
        })
      })
      return formDataList
    },
    onAddPeople () {
      this.formItem.page03.push({
        '03FZDY1101': '',
        trainLifespanYear: '3',
        trainHaveHours: null,
        trainAchievement: null,
        trainEndTime: null,
        trainHours: null,
        trainResult: null,
        trainStartTime: null,
        trainingAddress: null,
        trainingClassName: null,
        trainingClassType: 'devObjectClass',
        trainingUnit: null,
        showBtn: true,
        trainFileId: null
      })
    },
    generatePage (index) {
      return eleventh.call(this, index)
    },
    //获取动态表单接口
    getDynamicFormInfo () {
      return new Promise((resolve, reject) => {
        this.$ajax({
          url: 'api-general/module/dynamicform/form/getFormStructure',
          method: 'get',
          data: {
            formId: this.formId,
            version: this.version
          }
        }).then(({data}) => {
          this.recommendFormList = []
          this.dynamicList = []
          const arr = data.data.formGroupList.filter(item => item.isEnabled == 1)
          arr.forEach((item) => {
            if (item.groupCode === 'bisFileList') {
              item.formPropertyList.push({
                displayName: '附件名称',
                propertyCode: 'configName',
                isEnabled: 1,
              }, {
                displayName: '附件',
                propertyCode: 'fileName',
                isEnabled: 1
              }, {
                displayName: '是否已上传',
                propertyCode: 'isYsc',
                isEnabled: 1,
                width: 100
              }, {
                displayName: '上传时间',
                propertyCode: 'fileUpDate',
                widgetType: 'DatePicker',
                isEnabled: 1,
                width: 120
              })
            }
            // 业务存储附件
            if (item.groupCode === 'bisFileList') {
              let fileList = []
              this.formItem[item.groupCode] = []
              item.formPropertyList.map(i => {
                if (i.widgetType === 'hidden') {
                  this.$set(this.bisFileList, i.propertyCode, []) // 初始化code为空数组
                  let propertyConfig = JSON.parse(i.propertyConfig) || {}
                  fileList.push({
                    ...i,
                    code: 'bisFileList',
                    props: propertyConfig,
                    attachFileConfigId: propertyConfig.data.attachFileConfigId,
                    downloadType: propertyConfig.downloadType,
                    fileId: '',
                    fileName: ''
                  })
                }
              })
              fileList.forEach(item => {
                this.configFileList.forEach(val => item.propertyCode === val.configCode && this.formItem.bisFileList.push({ ...item, ...val }))
              })
            }
            this.dynamicList.push(item)
          })
          this.$nextTick(() => {
            if (this.pmdUserId) {
              this.getDetail()
            }
          })
          resolve()
        })
      })
    },
    getDetail () {
      this.$ajax({
        url: '/api-uum/module/pmd/person/getPmd11',
        method: 'GET',
        data: {
          pmdUserId: this.pmdUserId
        }
      }).then(data => {
        this.formItem.pmdUserId = this.$route.query.pmdUserId
        this.formItem.page02 = data.data.data.page01
        this.formItem.page03 = data.data.data.page03
        this.formItem.page02.applyTime = timeFormat(data.data.data.page01.applyTime)
        this.formItem.page02.activistDate = timeFormat(data.data.data.page01.activistDate)
        this.formItem.page02.developDate = timeFormat(data.data.data.page01.developDate)
        // 附件信息
        this.uploadFileList = data.data.data?.bisFileList ? data.data.data?.bisFileList : {} // 保存附件信息
        if (this.formItem.bisFileList && this.formItem.bisFileList.length && this.uploadFileList.length > 0) {
          this.formItem.bisFileList.map(item => {
            item.fileId = this.uploadFileList[item.propertyCode] ? this.uploadFileList[item.propertyCode][0]?.fileId : ''
            item.fileName = this.uploadFileList[item.propertyCode] ? this.uploadFileList[item.propertyCode][0]?.fileName : ''
            item.isYsc = this.uploadFileList[item.propertyCode][0]?.fileName ? '已上传' : '未上传'
            item.uploadTime = timeFormat(this.uploadFileList[item.propertyCode][0]?.uploadTime)
          })
        }
      })
    },
    onSelectreeChange (list, type) {
      this.formItem.individualStatus = list[0].data.itemCode
    },
    onChangeTreeValue (res, r, item) {
      if (r == 'orgName') {
        this.orgInfo = res[0]
      }
    },
    changeTableData (groupCode, index, key, value) { 
      this.formItem[groupCode][index][key] = value
    },
    downTemp (type, data) {
      this.$ajax({
        url: 'api-uum/module/pmd/person/getAttacheFile',
        method: 'GET',
        data: {
          attachFileConfigId: data.attachFileConfigId
        }
      }).then(({data}) => {
        let fileId = data.data.fileId
        let url = `api-uum/file/downloadFile?fileId=${fileId}` + '&access_token=' + sessionStorage.getItem('token') + '&currentAuthScopeId=' + sessionStorage.getItem('listOrgId')
        window.open(url)
      })
    },
    onListUploadSuccess (code, index, key, file) {
      this.formItem[code][index]['trainFileId'] = file.fileId
      this.formItem[code][index][key] = file.fileId
      if (!this.uploadFileList[key]) this.uploadFileList[key] = []
      this.uploadFileList[key].push(file)
    },
    onListUploadClear (code, index, key, file) {
      this.formItem[code][index]['trainFileId'] = ''
      this.formItem[code][index][key] = ''
      this.uploadFileList[key].map((item, _index) => {
        if (file.fileId == item.fileId) {
          this.uploadFileList[key].splice(_index, 1)
        }
      })
    },
    //获取数据字典
    getCodeTable (code) {
      return new Promise((resolve, reject) => {
        this.$ajax({
          url: 'api-general/module/portal/user/outGetList',
          method: 'get',
          data: {
            dictCode: code,
            merge: false,
            bizLineCode: 'YWX01'
          }
        }).then(({data}) => {
          this.dataDictionary[code] = data.data.map(item => ({
            label: item.title,
            value: item.value
          }))
          resolve()
        })
      })
    },
    // 列表校验
    validateTable (groupCode, validateList) {
      const list = []
      return new Promise((resolve, reject) => {
        validateList.map((items, index) => {
          let validator = new Schema(this.tableRules[groupCode])
          validator.validate(items, (errors, fields) => {
            // 初始化
            items.errorQueue && (items.errorQueue = null)
            if (!errors) {
              resolve(list)
              return
            }
            errors.forEach(item => {
              this.$set(items, 'errorQueue', Object.assign(items.errorQueue || {}, {
                [item.field]: true
              }))
              list.push(item)
            })
            reject(list)
          })
        })
      })
    },
    // 点击提交(待办进入) 非编辑接口
    click () {
      Promise.all([
        this.validateTable('page03', this.formItem.page03)
      ]).then(([list]) => { 
        if (list.length > 0) {
          return this.$Message.error(list[0].message ? list[0].message : '请完善集中培训信息列表！')
        } else { 
          let isList = true, fileName = ''
          this.formItem.page03.map(item => {
            if (new Date(item.trainStartTime) - new Date(item.trainEndTime) >= 86400000) {
              isList = false
              fileName = '【' + item.trainingClassName + '】集中培训开始日期不能晚于集中培训结束时间'
            }
          })
          if (!isList) return this.$Message.error(`${fileName || ''}！`)
          this.$ajax({
            url: '/api-uum/module/pmd/person/savePmd11',
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            disabledData: true,
            data: {
              orgId: this.orgId,
              ...this.formItem,
              page02: {
                applyTime: this.formItem.page02.applyTime ? timeFormat(this.formItem.page02.applyTime) : '',
                activistDate: this.formItem.page02.activistDate ? timeFormat(this.formItem.page02.activistDate) : '',
                developDate: this.formItem.page02.developDate ? timeFormat(this.formItem.page02.developDate) : ''
              },
              bisFileList: this.uploadFileList,
              pmdUserId: this.formItem.pmdUserId || ''
            }
          }).then(data => {
            this.$Message.success('提交成功')
            this.back()
          })
        }
      })   
    },
    // 可编辑的接口
    save () {
      Promise.all([
        this.validateTable('page03', this.formItem.page03)
      ]).then(([list]) => { 
        if (list.length > 0) {
          return this.$Message.error(list[0].message ? list[0].message : '请完善集中培训信息列表！')
        } else { 
          let isList = true, fileName = ''
          this.formItem.page03.map(item => {
            if (new Date(item.trainStartTime) - new Date(item.trainEndTime) >= 86400000) {
              isList = false
              fileName = '【' + item.trainingClassName + '】集中培训开始日期不能晚于集中培训结束时间'
            }
          })
          if (!isList) return this.$Message.error(`${fileName || ''}！`)
          this.$ajax({
            url: '/api-uum/module/pmd/person/savePmd11Save',
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            disabledData: true,
            data: {
              orgId: this.orgId,
              ...this.formItem,
              page02: {
                applyTime: this.formItem.page02.applyTime ? timeFormat(this.formItem.page02.applyTime) : '',
                activistDate: this.formItem.page02.activistDate ? timeFormat(this.formItem.page02.activistDate) : '',
                developDate: this.formItem.page02.developDate ? timeFormat(this.formItem.page02.developDate) : ''
              },
              bisFileList: this.uploadFileList,
              pmdUserId: this.formItem.pmdUserId || ''
            }
          }).then(data => {
            this.$Message.success('提交成功')
            this.back()
          })
        }
      })   
    },
    back () {
      this.$router.push({
        path: 'recruitingPartyMembersProgressKanban'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/.iv-main-background {
  background: #fff;
  height: 100%;
}
</style>