<template>
  <div class="iv-bulletin-board iv-m-b-16" :class="{'p-44': !data.noTitle}" :style="{height: data.noTitle ? '100px' : '220px'}">
    <Row type="flex" justify="start" align="middle" class="iv-board-row">
      <Col v-if="!data.noTitle" span="2" style="min-width:150px;" class="iv-board-col" :class="{ 'iv-desc-active': data.list[0].state == 'ongoing' || data.list[0].state == 'complete', 'iv-desc-going': data.list[0].state == 'ongoing', 'iv-desc-auditing': data.list[0].state == 'reject',}">
        <div class="iv-desc-title iv-board-div">
          <IconFont :type="data.icon" class="item-icon" />
          <span class="iv-absolute-span iv-color-red iv-fs-18">{{data.title}}</span>
        </div>
        <IconFont type="yuyinjiantou" class="iv-tips-arrow" :class="{ 'iv-fc-active': data.list[0].state == 'ongoing' || data.list[0].state == 'complete' || data.list[0].state == 'default'}" />
      </Col>
      <Col v-if="data.noTitle" :span="1" class="iv-no-title">
        <div class="iv-desc-footer iv-board-div">
          <IconFont type="doubleright" class="item-icon" />
        </div>
        <IconFont style="color:#FEA200;" type="yuyinjiantou" class="iv-tips-arrow" />
      </Col>
      <Col
        v-for="(item, index) in data.list"
        :key="index"
        :span="span"
        class="iv-board-col iv-board-for"
        :class="{'iv-full-col-no': data.noTitle && data.list.length == 8, 'iv-full-col': !data.noTitle && data.list.length == 8,'iv-desc-default': item.state == 'default', 'iv-desc-active': item.state == 'complete', 'iv-desc-going': item.state == 'ongoing', 'iv-desc-auditing': item.state == 'reject' }"
      >
        <Poptip
          width="250"
          v-if="item.showPoptip"
          trigger="hover"
          :content="item.content"
          :key="index"
          placement="bottom"
        >
          <div
            class="iv-desc-content iv-board-div iv-pointer"
            @click="onStep(item, index)"
          >
            <IconFont :type="getIconName(item)" class="item-icon item-icon-for" />
            <span class="iv-absolute-span iv-fs-16">{{item.title}}</span>
            <span v-if="item.step == 1 && meetingName" class="iv-absolute-span iv-webkit iv-fs-16">会议名称：{{meetingName}}</span>
            <div v-if="item.top" class="iv-fs-14 iv-fc-65 iv-top">{{item.top.label}}<span class="iv-color-green">{{item.top.value}}</span></div>
            <div v-if="item.bottom" class="iv-fs-14 iv-fc-65 iv-bottom">{{item.bottom.label}}<span class="iv-color-red">{{item.bottom.value}}</span></div>
          </div>
          <div slot="content" v-if="item.customContent" v-html="item.content"></div>
          <template v-if="item.infoList" slot="content" >
            <div class="iv-infotip-wrapper" v-for="(tip, index) in item.infoList" :key="index">
              <div v-if="index == 0" class="iv-fw-b iv-fs-16">{{tip.title}}</div>
              <p class="iv-fs-16" v-if="index != 0">
                <span class="iv-star iv-fw-b iv-symbol-mr8">
                <IconFont
                  class="icon-star-fill iv-fs-18"
                  type="outdent"></IconFont>
                  {{tip.title}}:
                </span>
                {{tip.info}}
              </p>
            </div>
          </template>
        </Poptip>
        <div
          v-else
          class="iv-desc-content iv-board-div iv-pointer"
           @click="onStep(item, index)"
        >
          <IconFont :type="getIconName(item)" class="item-icon item-icon-for" />
          <span class="iv-absolute-span iv-fs-16">{{item.title}}</span>
          <span v-if="item.step == 1 && meetingName" class="iv-absolute-span iv-webkit iv-fs-16">会议名称：{{meetingName}}</span>
          <div v-if="item.top" class="iv-fs-14 iv-fc-65 iv-top">{{item.top.label}}<span class="iv-color-green">{{item.top.value}}</span></div>
          <div v-if="item.bottom" class="iv-fs-14 iv-fc-65 iv-bottom">{{item.bottom.label}}<span class="iv-color-red">{{item.bottom.value}}</span></div>
        </div>
        <IconFont type="yuyinjiantou" class="iv-tips-arrow" :class="{ 'iv-fc-active': item.state == 'complete' || item.state == 'default'}" />
        <!-- 签到二维码 -->
        <!-- <div
          v-if="item.step == 4 && item.state === 'ongoing'"
          :key="index"
          class="iv-absolute-span iv-fs-16"
          style="bottom: 35px; min-width: 20px;"
        >
          <div class="iv-custom-img">
            <img :src="require('images/qr.png')" alt="签到二维码">
          </div>
          <IconFont type="qrcode" class="" />
        </div> -->
      </Col>
      <Col
        v-if="data.list.length"
        :span="getLastSpan()"
        class="iv-board-col iv-board-footer"
        :class="{'iv-desc-default': data.list[data.list.length - 1].state == 'default','iv-desc-active': data.list[data.list.length - 1].state == 'complete', 'iv-desc-going': data.list[data.list.length - 1].state == 'ongoing', 'iv-desc-auditing': data.list[data.list.length - 1].state == 'reject' }"
      >
        <div class="iv-desc-footer iv-board-div">
          <IconFont type="doubleright" class="item-icon" />
        </div>
      </Col>
    </Row>
  </div>
</template>
<script>
import IconFont from '@/components/iconfont'
export default {
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    },
    meetingName: {
      type: String,
      default () {
        return ''
      }
    },
    span: {
      type: Number,
      default () {
        return 4
      }
    }
  },
  data () {
    return {
      iconMap: {
        // wait: 'shalou3',
        // ongoing: 'dang',
        // auditing: 'dang',
        // complete: 'tijiaoqueren'
        wait: 'line',
        reject: 'bohui3',
        ongoing: 'shalou3',
        complete: 'tijiaoqueren',
        default: 'dang'
      }
    }
  },
  components: {
    IconFont
  },
  watch: {
    data: {
      handler (val) {
        val.list.map(item => {
          item.showPoptip = item.content != '' && item.content != null && item.content != undefined
        })
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    getIconName (item) {
      return item.icon ? item.icon : this.iconMap[item.state]
    },
    getLastSpan () {
      if (this.data.noTitle) {
        return Math.floor(24 - (this.data.list.length * 3) - 1)
      } else {
        return Math.floor(24 - (this.data.list.length * 3) - 2)
      }
    },
    getSpan (len, type) {
      if (type && type == 'last') {
        let l = len < 4 ? 5 : Math.floor(16 / len)
        return 24 - 4 - (l * len)
      }
      if (len < 5) {
        return 3
      }
      return Math.floor(16 / len)
    },
    onStep (item, index) {
      this.$emit('on-step-click', item, index)
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/base/variable.scss';
.iv-bulletin-board {
  width: 100%;
  height: 220px;
  background: #fff;
  border-radius: 10px;
  &.p-44 {
    padding: 0 44px;
    padding-left: 40px;
  }
}
.iv-board-row {
  // padding: 0 40px;
  height: 100%;
  flex-wrap: nowrap;
  .iv-board-first {
    display: flex;
    align-items: center;
    height: 100%;
    &::before {
      content: '';
      width: 50%;
      left: 0;
      height: 2px;
      background: #FEA200;
      z-index: 1;
      position: absolute;
    }
  }
  .iv-board-col {
    display: flex;
    align-items: center;
    height: 100%;
    width: 13.5%;
    &::before {
      content: '';
      width: 50%;
      left: 0;
      height: 2px;
      background: rgba(0, 0, 0, 0.16);
      z-index: 1;
      position: absolute;
    }
    &::after {
      content: '';
      width: 50%;
      right: 0;
      height: 2px;
      background: rgba(0, 0, 0, 0.16);
      z-index: 1;
      position: absolute;
    }
    &.iv-desc-active {
      &::after {
        background: #FEA200;
      }
      &::before {
        background: #FEA200;
      }
      .item-icon-for {
        color: #52C41A;
      }
      .iv-desc-content {
        border-color: #52C41A;
      }
      .iv-fc-active {
        color: #FEA200;
      }
    }
    &.iv-desc-going {
      &::before {
        background: #FEA200;
      }
      .item-icon-for {
        color: #FA2900;
      }
      .iv-desc-content {
        border-color: #FA2900;
      }
    }
    &.iv-desc-default {
      &::after {
        background: #FEA200;
      }
      &::before {
        background: #FEA200;
      }
      .item-icon-for {
        color: #FA2900;
      }
      .iv-desc-content {
        border-color: #FA2900;
      }
      .iv-fc-active {
        color: #FEA200;
      }
    }
    &.iv-desc-auditing {
      &::before {
        background: #FEA200;
      }
      .item-icon-for {
        color: #ffe016;
      }
      .iv-desc-content {
        border-color: #ffe016;
      }
    }
    &.iv-board-footer {
      &.iv-desc-going {
        &::before {
          background: rgba(0, 0, 0, 0.16);
        }
      }
    }
    .item-icon {
      color: rgba(0, 0, 0, 0.24);
    }
    .iv-absolute-span {
      position: absolute;
      bottom: -50px;
      // min-width: 143px;
      min-width: 176px;
      text-align: center;
      .iv-custom-img {
        display: none;
        img {
          background-color: #fff;
          position: relative;
          z-index: 2;
        }
      }
      &:hover {
        .iv-custom-img {
          display: block;
        }
      }
    }
    .iv-board-title {
      &::after {
        content: '';
        width: 50%;
        right: 0;
        height: 2px;
        background: rgba(0, 0, 0, 0.16);
        z-index: 1;
        position: absolute;
      }
    }
    .iv-board-div {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 2;
    }
    .iv-tips-arrow {
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -12px;
      z-index: 2;
      height: 20px;
      background: #fff;
      color: rgba(0, 0, 0, 0.24);
      width: 15.5px;
    }
    &.ivu-col-span-2 {
      width: 12.333333%!important;
    }
  }
  .iv-board-for {
    justify-content: center;
  }
  .iv-board-footer {
    flex: 1;
    justify-content: right;
  }
  .iv-desc-title {
    width: 120px;
    height: 120px;
    background: #cee9fc;
    border-radius: 50%;
    border: 16px solid #f5faff;
    text-align: center;
    .item-icon {
      color: $iv-main-color;
      font-size: 42px;
    }
  }
  .iv-desc-content {
    width: 60px;
    height: 60px;
    background: #FFFFFF;
    border-radius: 88px;
    border: 6px solid rgba(0, 0, 0, 0.24);
    .iv-absolute-span {
      top: 70px;
      color: rgba(0, 0, 0, 0.88);
    }
    .item-icon {
      font-size: 28px;
    }
  }
  .iv-desc-footer {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    // background: #FA2900;
    background: $iv-main-color;
    text-align: center;
    .item-icon {
      color: #fff;
      // font-size: 14px;
    }
  }
  .iv-no-title {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: space-between;
    &::after {
      content: '';
      width: calc(100% - 40px);
      left: 24px;
      height: 2px;
      background: #FEA200;
      z-index: 1;
      position: absolute;
    }
  }
}
.iv-top, .iv-bottom {
  position: absolute;
  min-width: 110px;
  color: rgba(0, 0, 0, 0.88);
  left: 72px;
}
.iv-top {
  top: 0px;
}
.iv-bottom {
  bottom: 0px;
}
.iv-color-red {
  color: $iv-main-color;
}
.iv-color-green {
  color: #52C41A;
}
.iv-webkit {
  bottom: -93px !important;
  height: 48px;
  -webkit-line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
/deep/ .ivu-poptip-popper {
  width: 400px!important;
}
// /deep/ .ivu-poptip-inner {
//   white-space: normal;
// }
/deep/ .ivu-poptip-body-content {
  white-space: normal;
}
// /deep/ .ivu-col-span-8 {
//   width: 31.9%;
// }
// /deep/ .ivu-col-span-2 {
//   width: 11.4%;
// }
.iv-full-col {
  &.ivu-col-span-3 {
    width: 11.28%;
    min-width: 14.28%;
  }
  &.ivu-col-span-2 {
    width: 10.333333%!important;
  }
}
.iv-full-col-no {
  &.ivu-col-span-3 {
    width: 11.8%;
  }
  &.ivu-col-span-4 {
    width: 11.666667%;
  }
}
</style>
<style scoped>
@media screen and (min-width: 2100px) {
  .iv-absolute-span {
    min-width: 144px!important;
  }
}
@media screen and (max-width: 1630px) {
  .iv-absolute-span {
    min-width: 95px!important;
  }
}
</style>
