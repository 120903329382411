<template>
  <div>
    <div style="background: #fff;height: 100%;">
      <div class="iv-main-width iv-p-t-40">
        <!--步骤看板-->
        <BulletinBoardProgress
          v-for="(item, index) in boardList"
          :key="index" :data="item"
          @on-step-click="onStepClick">
        </BulletinBoardProgress>
      </div>
    </div>
    <!-- 内容 -->
    <components
      v-if="showTab"
      :beforeInfoNine="beforeInfoNine"
      :beforeInfo="beforeInfo"
      :infoMation="infoMation"
      :readOnly="false"
      :is="currentComponent">
    </components>
  </div>
</template>

<script>
import BulletinBoardProgress from '@/components/bulletin-board-progress'
import importComponents from './components'

export default {
  data () {
    return {
      name: 'stepList',
      step: this.$route.query.step || 0,
      stage: this.$route.query.stage || 0,
      currentComponent: null,
      infoMation: {},
      showTab: true,
      pmdUserId: this.$route.query.pmdUserId,
      stepList: [],
      boardList: [],
      currentStep: {},
      beforeInfo: {},
      beforeInfoNine: {},
      stepCode: this.$route.query.stepCode,
      formCode: this.$route.query.formCode,
      routeMap: {
        'FZDY0101': '01submitApplication',
        'FZDY0601': '06trainingEducationInspection',
        'FZDY1001': '10conductPoliticalReview',
        'FZDY1101': '11conductCentralizedTraining',
        'FZDY1401': '14FillInPartyMembership',
        'FZDY2102': '21continuingEducationSurvey',
        'FZDY2201': '22employmentConfirmationApplication',
        'FZDY0501': '05cultivationContact'
      },
      stepMap: {
        '01submitApplication': {
          step: 0,
          stage: 0
        },
        '06trainingEducationInspection': {
          step: 3,
          stage: 1
        },
        '10conductPoliticalReview': {
          step: 3,
          stage: 2
        },
        '11conductCentralizedTraining': {
          step: 4,
          stage: 2
        },
        '14FillInPartyMembership': {
          step: 2,
          stage: 3
        },
        '21continuingEducationSurvey': {
          step: 2,
          stage: 4
        },
        '22employmentConfirmationApplication': {
          step: 3,
          stage: 4
        },
      }
    }
  },
  created () {
    if (this.stepCode) {
      this.step = this.stepMap[this.stepCode].step
      this.stage = this.stepMap[this.stepCode].stage
    }
    this.getStages()
  },
  methods: {
    getStages () {
      this.$ajax({
        url: 'api-uum//module/pmd/person/getStepPorcess',
        method: 'GET',
        data: {
          businessId: this.pmdUserId || '',
          orgId: sessionStorage.getItem('listOrgId')
        }
      }).then(({ data }) => {
        this.stepList = []
        data.data.map((item, index) => {
          // if (item.state == 0) {
          //   this.stage = this.stage || index
          // }
          this.stepList.push(item)
        })
        if (sessionStorage.getItem('showMeetingBtn')) {
          sessionStorage.removeItem('showMeetingBtn')
        }
        this.getCurrentStepList()
      })
    },
    getCurrentStepList () {
      this.currentStep = this.stepList[this.stage]
      this.boardList = [{
        title: this.currentStep.stageShowName,
        noTitle: true,
        icon: 'training1',
        list: []
      }]
      this.currentStep.stepStateList.map((item, index) => {
        item.component = item.stepFormState && item.stepFormState.formCode
        let list = item.stepDescription ? item.stepDescription.split('@') : []
        let listArr = []
        if (item.stepCode == '04partyCommitteeFiling' || item.stepCode == '08submitPartyCommitteeFiling' || item.stepCode == '13partyCommitteeInquiry' || item.stepCode == '24partyCommitteeApproval') {
          list.forEach((item, index) => {
            if (index > 1) {
              listArr.push({
                title: item.split('a')[0],
                info: item.split('a')[1]
              })
            }
          })
        } else if (item.stepCode == '15DiscussionBranchMeeting') {
          list.forEach((item, tipIndex) => {
            if (tipIndex < 3) {
              listArr.push({
                title: item.split('a')[0],
                info: item.split('a')[1]
              })
            }
          })
        } else if (item.stepCode == '17partyCommitteeApproval') {
          list.forEach((item, tipIndex) => {
            if (tipIndex < 5) {
              listArr.push({
                title: item.split('a')[0],
                info: item.split('a')[1]
              })
            }
          })
        } else {
          list.forEach(item => {
            listArr.push({
              title: item.split('a')[0],
              info: item.split('a')[1]
            })
          })
        }
        this.boardList[0].list.push({
          state: this.getState(item),
          title: item.stepName,
          content: item.stepDescription,
          showPoptip: item.stepDescription && item.stepDescription.length > 0,
          step: index,
          active: item.active,
          activeState: item.state,
          stepFormState: item.stepFormState,
          infoList: listArr
        })
        if (item.active == true) {
          // if (this.$route.query.stepCode) {
          //   this.step = index
          // } else if (!this.currentStep.stepStateList[this.step]) {
          //   this.step = this.step || index
          // }
        }
      })
      this.passStep()
    },
    getState (item) {
      if (item.state == -1) {
        return 'wait'
      } else if (item.state == 0) {
        return 'ongoing'
      } else if (item.state == 1) {
        return 'complete'
      }
    },
    passStep (item, index) {
      this.beforeInfo = this.stepList[1].stepStateList[0]
      this.beforeInfoNine = this.stepList[1].stepStateList[1]
      this.infoMation = this.currentStep.stepStateList[this.step]
      this.currentComponent = this.formCode || this.infoMation.component
      this.showTab = false
      this.$nextTick(() => {
        this.showTab = true
      })
    },
    onStepClick (item, stepIndex) {
      if (this.routeMap[item.stepFormState.formCode]) {
        if (item.active || item.activeState == 1) {
          this.step = stepIndex
          this.getStages()
        } else {
          this.$Message.success('还未进行到此步骤!')
        }
      } else {
        this.$Message.success('无法查看党组织维护信息。')
        return
      }
    },
    clickStep (item, index) {
      this.stage = index
      this.step = 0
      this.infoMation = item.stepStateList[0]
      this.getStages()
    },
    getSecondActive () {
      return this.stepList[0].stepStateList[1].active
    }
  },
  components: {
    BulletinBoardProgress,
    ...importComponents
  }
}
</script>

<style lang="scss" scoped>
.iv-m-b-t-70 {
  margin-bottom: 70px !important;
}
</style>
