<template>
  <div class="iv-border-wrapper iv-radius">
    <div class="iv-border-header iv-text-center" v-if="isShowTitle">
      <i class="iv-left iv-radius-item"></i>
      <span class="iv-radius iv-fw-b iv-fs-14">{{title}}</span>
      <i class="iv-right iv-radius-item"></i>
    </div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: '全部参加人员：人，应签到人员：人'
    },
    isShowTitle: {
      type: Boolean,
      default: true
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'variable/variable.scss';
.iv-border-wrapper {
  border: 1px solid rgba(217,217,217,1);
  position: relative;
  .iv-border-header {
    position: absolute;
    width: 100%;
    top: -16px;
    font-size: 0;
    .iv-radius-item {
      display: inline-block;
      width: 16px;
      height: 10px;
      background: #fff;
      vertical-align: middle;
      &:before {
        content: '';
        float: left;
        width: 9px;
        height: 9px;
        background:rgba(217,217,217,1);
        border-radius: 50%;
      }
      &.iv-right {
        &:before {
          float: right;
        }
      }
    }
    span {
      background: rgba(245,245,245,1);
      padding: 6px 20px;
      display: inline-block;
      vertical-align: middle;
    }
  }
}
</style>