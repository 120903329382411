<template>
  <div style="background: #fff;height: 100%;margin-top: -16px;">
    <div class="iv-main-width  iv-p-b-40 iv-p-t-40">
      <!-- <h1 class="iv-fs-24 iv-fc-85 iv-vertical-line">{{ infoMation.stepDescription }}</h1> -->
    <div class="iv-main-width">
      <template v-for="(item, index) in pageafterList">
        <components
          :key="index"
          :infoMation="item"
          :readOnly="true"
          :is="item.stepFormState.formCode">
        </components>
      </template>
      <LayoutTitle :title="item.groupName" v-for="(item, index) in dynamicList" :key="item.groupId">
      <FormLayout
        v-if="item.formPropertyList.length && item.groupType == 1"
        ref="sixthStep1" 
        :readOnly="!infoMation.active"
        :model="formItem[item.groupCode]"
        :list="generatePage(item, index)"
        @on-selectree-change="onSelectreeChange"
        @on-change-tree="onChangeTreeValue"
        >
      </FormLayout>
    </LayoutTitle>
    <LayoutTitle :title="'培养教育考察'">
      <template>
        <SelectTreeLayout class="iv-p-16 iv-m-t-24" :title="titleList[key]" v-for="(key, layoutIndex) in getChildList(childLayoutList)" :key="layoutIndex">
          <FunctionFormLayout
            class="iv-symbol-pb12 iv-m-t-20"
            ref="FormLayout"
            :showAdd="layoutIndex == (titleList.length-1)"
            :showDel="layoutIndex != 0"
            @onFromDel="onFromDelete(layoutIndex)"
            @onFromAdd="onFromAdd">
            <div v-for="(_item, _index) in childLayoutList[key]" :key="_index">
              <FormLayout
                ref="sixthStep2" 
                :readOnly="readOnly"
                v-if="_item.groupType == 1"
                :model="formItem['page02'][layoutIndex][_item.groupCode]"
                :list="generatePage(_item, layoutIndex, _index)"
                @on-selectree-change="onSelectreeChange"
                @on-change-tree="onChangeTreeValue"
                @on-change="onChange(...arguments, layoutIndex)"
                @on-upload-success="uploadSuccess(...arguments, layoutIndex)"
                @on-input-clear="onClearFile(...arguments, layoutIndex)"
                >
              </FormLayout>
              <DragTable
                class="iv-m-t-24"
                noSearch
                v-if="_item.groupType != 1"
                :showEdit="false"
                :showDelete="false"
                :showSelect="false"
                :showPage="false"
                ref="DragTable" 
                :immediateForce="true" 
                :columns="generatePage(_item, layoutIndex, _index)" 
                :tableData="formItem['page02'][layoutIndex][_item.groupCode] || []">
              </DragTable>
            </div>
          </FunctionFormLayout>
          
        </SelectTreeLayout>
      </template>
    </LayoutTitle>
    <FooterOperator>
      <Button class="iv-m-r-8"  type="submit" @click="click" v-if="infoMation.active">提交</Button>
      <Button class="iv-m-r-8"  type="save" @click="save" v-else>保存</Button>
      <Button class="iv-m-r-8"  type="back" @click="back">返回</Button>
    </FooterOperator>
    </div>
  </div>
  </div>
</template>

<script>
import FormLayout from '@/components/layout/form'
import LayoutTitle from '@/components/layout/layout-title'
import sixth from './configs/sixth'
import FooterOperator from '@/components/footer-operator'
import { timeFormat } from '@/utils'
import DragTable from '@/components/drag-table-inline'
// import { prefixPath } from '@/config'
import SelectTreeLayout from '@/components/select-tree-business/layout'
import FunctionFormLayout from '@/components/function-from-layout'
import importComponents from './combination'
import { cloneDeep } from 'lodash'
export default {
  components: {
    DragTable,
    FormLayout,
    LayoutTitle,
    FooterOperator,
    SelectTreeLayout,
    FunctionFormLayout,
    importComponents
  },
  props: {
    infoMation: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      formItem: {
        page01: {
          applyTime: '',
          activistDate: ''
        },
        page02: [{
          inspect: {},
          reoprt: {},
          inspectUsers: []
        }],
        bisFileList: []
      },
      dynamicList: [],
      readOnly: false,
      version: this.$route.query.version,
      formId: this.$route.query.formId,
      orgId: sessionStorage.getItem('orgId'),
      bizLineCode: 'YWX01',
      pmdUserId: this.$route.query.pmdUserId, //YWX01
      content: this.$route.query.title,
      bisFileList: [],
      configFileList: [],
      uploadFileList: {},
      titleList: ['年度 季度'],
      quarterMap: {
        1: '第一季度',
        2: '第二季度',
        3: '第三季度',
        4: '第四季度'
      },
      childLayoutList: {}
    }
  },
  beforeCreate () {
    this.$options.propsData.infoMation.beforeStepFormStates.map(item => {
      this.$options.components[item.formCode] = importComponents[item.formCode]
    })
  },
  created () {
    this.formId = this.infoMation.stepFormState.formId
    this.version = this.infoMation.stepFormState.formVersion
    this.pageafterList = this.changeList(this.infoMation.beforeStepFormStates || [])
    this.getFileconfiglist()
  },
  methods: {
    getChildList (list) {
      return Object.keys(list)
    },
    changeList (list) {
      let formDataList = []
      list.map(item => {
        item.noShowList = true
        formDataList.push({
          stepFormState: item
        })
      })
      return formDataList
    },
    generatePage (item, index) {
      return sixth.call(this, item, index)
    },
    getFileconfiglist () {
      this.$ajax({
        url: 'api-uum/module/pmd/person/fileconfiglist',
        method: 'GET',
        data: {
          configDesc: '01-递交入党申请书'
        }
      }).then(data => {
        this.configFileList = data.data.data
        this.getDynamicFormInfo()
      })
    },
    //获取动态表单接口
    getDynamicFormInfo () {
      return new Promise((resolve, reject) => {
        this.$ajax({
          url: 'api-general/module/dynamicform/form/getFormStructure',
          method: 'get',
          data: {
            formId: this.formId,
            version: this.version
          }
        }).then(({data}) => {
          this.recommendFormList = []
          this.dynamicList = []
          let childList = []
          const arr = data.data.formGroupList.filter(item => item.isEnabled == 1)
          arr.forEach((item) => {
            if (item.groupCode === 'bisFileList') {
              item.formPropertyList.push({
                displayName: '附件名称',
                propertyCode: 'configName',
                isEnabled: 1,
              }, {
                displayName: '附件',
                propertyCode: 'fileName',
                isEnabled: 1
              }, {
                displayName: '是否已上传',
                propertyCode: 'isYsc',
                isEnabled: 1,
                width: 100
              }, {
                displayName: '上传时间',
                propertyCode: 'fileUpDate',
                widgetType: 'DatePicker',
                isEnabled: 1,
                width: 120
              })
            }
            // 业务存储附件
            if (item.groupCode === 'bisFileList') {
              let fileList = []
              this.formItem[item.groupCode] = []
              item.formPropertyList.map(i => {
                if (i.widgetType === 'hidden') {
                  this.$set(this.bisFileList, i.propertyCode, []) // 初始化code为空数组
                  let propertyConfig = JSON.parse(i.propertyConfig) || {}
                  fileList.push({
                    ...i,
                    code: 'bisFileList',
                    props: propertyConfig,
                    attachFileConfigId: propertyConfig.data.attachFileConfigId,
                    downloadType: propertyConfig.downloadType,
                    fileId: '',
                    fileName: ''
                  })
                }
              })
              fileList.forEach(item => {
                this.configFileList.forEach(val => item.propertyCode === val.configCode && this.formItem.bisFileList.push({ ...item, ...val }))
              })
            }
            if (item.groupCode == 'page01') {
              this.dynamicList.push(item)
            } else {
              let _code = item.groupCode == 'page02' ? 'reoprt' : (item.groupCode == 'page03' ? 'inspectUsers' : 'inspect')
              item.groupCode = _code
              childList.push(item)
            }
          })
          this.childLayoutList[0] = childList
          this.$nextTick(() => {
            if (this.pmdUserId) {
              this.getDetail()
            }
          })
          resolve()
        })
      })
    },
    getDetail () {
      this.$ajax({
        url: '/api-uum/module/pmd/person/getPmd06',
        method: 'GET',
        data: {
          pmdUserId: this.pmdUserId
        }
      }).then(data => {
        this.uploadFileList = data.data.data.bisFileList || {} // 保存附件信息
        this.pmdAssignCultivateUserList = cloneDeep(data.data.data.pmdAssignCultivateUserList || [])
        this.formItem.page02 = data.data.data.page02?.length ? data.data.data.page02 : [{
          reoprt: {},
          inspectUsers: cloneDeep(this.pmdAssignCultivateUserList),
          inspect: {
            inspectOrgName: sessionStorage.getItem('listOrgName'),
            inspectOrgId: sessionStorage.getItem('listOrgId')
          }
        }]
        this.pmdUserId = data.data.data.pmdUserId
        this.formItem.pmdUserId = data.data.data.pmdUserId
        this.formItem.page01 = data.data.data.page01
        this.formItem.page01.applyTime = timeFormat(this.formItem.page01.applyTime)
        this.formItem.page01.activistDate = timeFormat(this.formItem.page01.activistDate)
        // 附件信息
        if (this.formItem.bisFileList && this.formItem.bisFileList.length) {
          this.formItem.bisFileList.map(item => {
            item.fileId = this.uploadFileList[item.propertyCode] ? this.uploadFileList[item.propertyCode][0]?.fileId : ''
            item.fileName = this.uploadFileList[item.propertyCode] ? this.uploadFileList[item.propertyCode][0]?.fileName : ''
          })
        }
        this.formItem.page02.map((item, index) => {
          if (index > 0) {
            this.childLayoutList[index] = cloneDeep(this.childLayoutList[0])
          }
          this.titleList[index] = item.reoprt.year ? (item.reoprt.year + '年度 ' + (this.quarterMap[item.reoprt.quarter])) : '年度 季度'
          item.inspect.inspectTime = timeFormat(item.inspect.inspectTime)
          if (this.uploadFileList['02FZDY0603']) {
            let file = this.uploadFileList['02FZDY0603'].find(file => { return file.fileId == item.inspect['fileId'] })
            item.inspect['02FZDY0603'] = file ? [file] : []
          }
          item.reoprt.year = item.reoprt.year ? item.reoprt.year + '' : ''
          item.reoprt.quarter = item.reoprt.quarter ? item.reoprt.quarter + '' : ''
          item.reoprt.reportTime = item.reoprt.reportTime ? timeFormat(item.reoprt.reportTime) : ''
          if (this.uploadFileList['02FZDY0602']) {
            let file = this.uploadFileList['02FZDY0602'].find(file => { return file.fileId == item.reoprt['fileId'] })
            item.reoprt['02FZDY0602'] = file ? [file] : []
          }
          item.inspectUsers = item.inspectUsers?.length ? item.inspectUsers : cloneDeep(this.pmdAssignCultivateUserList)
          item.inspectUsers.forEach(value => {
            value.inspectTime = timeFormat(value.inspectTime)
          })
          this.uploadFileList['02FZDY0604'] && item.inspectUsers.map(user => {
            let file = this.uploadFileList['02FZDY0604'].find(file => { return file.fileId == user.fileId })
            user['02FZDY0604'] = file ? [file] : []
          })
        })
      })
    },
    onFromDelete (index) {
      this.childLayoutList.splice(index, 1)
      this.titleList.splice(index, 1)
      this.formItem.page02.splice(index, 1)
    },
    onFromAdd () {
      this.childLayoutList[this.titleList.length] = cloneDeep(this.childLayoutList[0])
      this.titleList.push('年度 季度')
      this.formItem.page02.push({
        reoprt: {},
        inspect: {},
        inspectUsers: []
      })
    },
    onChange (item, value, index, index1) {
      if (value) {
        if (item.key == 'year') {
          this.formItem.page02[index1].reoprt[item.key] = timeFormat(value, 'yyyy')
        }
        if (item.key == 'year' || item.key == 'quarter') {
          this.$set(this.titleList, index1, timeFormat(this.formItem['page02'][index1].reoprt['year'] || '', 'yyyy') + '年度 ' + (this.quarterMap[this.formItem['page02'][index1].reoprt['quarter']] || ''))
        }
      } else {
        this.formItem.page02[index1].reoprt[item.key] = ''
      }
    },
    onSelectreeChange (list, type) {
      this.formItem.individualStatus = list[0].data.itemCode
    },
    onChangeTreeValue (res, r, item) {
      if (r == 'orgName') {
        this.formItem.page01.orgName = res[0].title
      }
    },
    // 表单附件上传
    uploadSuccess (file, key, list, item, index1) {
      if (file.success) {
        this.formItem['page02'][index1].reoprt['fileId'] = file.response.data.fileId
        this.uploadFileList[key] = []
        this.uploadFileList[key][index1] = file.response.data
        this.$Message.success('上传成功')
        this.formItem['page02'][index1].reoprt[key] = [this.uploadFileList[key][index1]]
      }
    },
    onClearFile (fileUpload, item, uploadFiles, key, index, index1) {
      this.formItem['page02'][index1].reoprt['fileId'] = ''
      this.uploadFileList[key].map((file, _index) => {
        if (file.fileId == item.fileId) {
          this.uploadFileList[key].splice(_index, 1)
        }
      })
    },
    downTemp (type, data) {
      this.$ajax({
        url: 'api-uum/module/pmd/person/getAttacheFile',
        method: 'GET',
        data: {
          attachFileConfigId: data.attachFileConfigId
        }
      }).then(({data}) => {
        let fileId = data.data.fileId
        let url = `api-uum/file/downloadFile?fileId=${fileId}` + '&access_token=' + sessionStorage.getItem('token') + '&currentAuthScopeId=' + sessionStorage.getItem('listOrgId')
        window.open(url)
      })
    },
    // 点击提交, 非编辑修改接口
    click () {
      this.formItem.page02.map(item => {
        item.reoprt.reportTime = new Date((item.reoprt.reportTime))
        item.reoprt.year = timeFormat(item.reoprt.year, 'yyyy')
      })
      let result
      Array.from(this.$refs.sixthStep2).forEach(item => {
        item.validate((valid) => {
          result = valid
        })
      })
      if (!result) return this.$Message.error('表单校验失败')
      let hasTitle = [], isRep = ''
      this.titleList.map(title => {
        if (hasTitle.includes(title)) {
          isRep = title
        } else {
          hasTitle.push(title)
        }
      })
      if (isRep) return this.$Message.error(`${isRep + ' 不能重复填写'}！`)
      this.formItem.page02.map(item => {
        item.reoprt.reportTime = timeFormat(item.reoprt.reportTime)
        item.reoprt.year = timeFormat(item.reoprt.year, 'yyyy')
      })
      this.$ajax({
        url: '/api-uum/module/pmd/person/savePmd06',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        disabledData: true,
        data: {
          ...this.formItem,
          page01: {
            applyTime: timeFormat(this.formItem.page01.applyTime),
            activistDate: timeFormat(this.formItem.page01.activistDate)
          },
          bisFileList: this.uploadFileList,
          orgId: this.orgId,
          pmdUserId: this.pmdUserId || ''
        }
      }).then(data => {
        this.$Message.success('提交成功')
        this.back()
      })
    },
    // 可编辑的接口
    save () {
      this.formItem.page02.map(item => {
        item.reoprt.reportTime = new Date((item.reoprt.reportTime))
        item.reoprt.year = timeFormat(item.reoprt.year, 'yyyy')
      })
      let result
      Array.from(this.$refs.sixthStep2).forEach(item => {
        item.validate((valid) => {
          result = valid
        })
      })
      if (!result) return this.$Message.error('表单校验失败')
      let hasTitle = [], isRep = ''
      this.titleList.map(title => {
        if (hasTitle.includes(title)) {
          isRep = title
        } else {
          hasTitle.push(title)
        }
      })
      if (isRep) return this.$Message.error(`${isRep + ' 不能重复填写'}！`)
      this.formItem.page02.map(item => {
        item.reoprt.reportTime = timeFormat(item.reoprt.reportTime)
        item.reoprt.year = timeFormat(item.reoprt.year, 'yyyy')
      })
      this.$ajax({
        url: '/api-uum/module/pmd/person/savePmd06Save',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        disabledData: true,
        data: {
          ...this.formItem,
          page01: {
            applyTime: timeFormat(this.formItem.page01.applyTime),
            activistDate: timeFormat(this.formItem.page01.activistDate)
          },
          bisFileList: this.uploadFileList,
          orgId: this.orgId,
          pmdUserId: this.pmdUserId || ''
        }
      }).then(data => {
        this.$Message.success('保存成功')
        this.back()
      })
    },
    back () {
      this.$router.push({
        path: 'recruitingPartyMembersProgressKanban'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/.iv-main-background {
  background: #fff;
  height: 100%;
}
</style>