<template>
  <div>
    <Modal
      loading
      transfer
      ref="Modal"
      @after-enter="afterEnter"
      @on-hidden="onHidden"
      :size="'bigLarge'"
      :title="title">
      <ModalLayout>
        <div>
          <FormLayout
            class="iv-m-t-20"
            ref="ComputedFormLayout"
            :rules="rules"
            :isShowSetting="false"
            :list="layoutList"
            :model="formItem"
            :readOnly="active == 'see'"
            >
          </FormLayout>
        </div>
      </ModalLayout>
      <div slot="footer">
        <Button
          type="cancel"
          noIcon
          class="iv-m-l-8 uit-cancel"
          @click="close">
          <span>{{'取消'}}</span>
        </Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import FormLayout from '@/components/layout/form'
import generateList from '../sixth.js'
import twentyFirstGenerateList from '../twenty-first.js'
import validate from '@/mixins/validate'
import { timeFormat } from '@/utils'
import Modal from '@/components/modal'
import ModalLayout from '@/components/modal/layout'
export default {
  name: 'addInspect',
  mixins: [validate],
  data () {
    return {
      layoutList: [],
      formItem: {
        createUserName: sessionStorage.getItem('userName'),
        uploadTime: timeFormat(new Date()),
        pmdUserName: '',
        pmdUserCode: '',
        '02FZDY0603': [],
        '05FZDY2102': [],
        cf5: '',
        cf6: ''
      },
      businessId: '',
      type: '',
      tableRules: {},
      active: this.$route.query.active,
      pmdUserType: this.$route.query.pmdUserType,
      formId: '',
      title: '上传支部考察意见',
      version: '',
      formCode: ''
    }
  },
  created () {
    this.rules = {}
    this.orgId = this.$route.query.orgId || sessionStorage.getItem('listOrgId')
  },
  methods: {
    open (params) {
      this.businessId = params.businessId
      this.type = params.type
      this.active = params.active
      this.pmdUserId = params.pmdUserId
      this.pmdUserType = params.pmdUserType
      this.formCode = this.pmdUserType == 10 || this.pmdUserType == 20 ? 'FZDY0603' : 'FZDY2103'
      this.formId = params.formId
      this.version = params.version
      this.$methods('Modal', 'open')
      this.showModal = true
      this.getDynamicFormInfo()
    },
    // 获取动态表单接口
    getDynamicFormInfo () {
      this.$ajax({
        url: 'api-general/module/dynamicform/form/getFormStructure',
        method: 'get',
        data: {
          formId: this.formId,
          version: this.version
        }
      }).then(async ({ data }) => {
        this.layoutList = []
        let groupList = data.data.formGroupList || []
        await groupList.map(async (item, index) => {
          if (item.isEnabled == 1 && item.groupCode == 'page04') {
            this.layoutList = await this.generatePage(item)
          }
        })
        if (this.businessId) {
          this.$nextTick(() => {
            this.getDetail()
          })
        } else {
          this.getPmdUser()
        }
      })
    },
    getPmdUser () {
      this.$ajax({
        url: 'module/pmd/process/getPmdUser',
        method: 'GET',
        data: {
          pmdUserId: this.pmdUserId
        }
      }).then(({data}) => {
        this.formItem.pmdUserName = data.data.userName
        this.formItem.pmdUserCode = data.data.userCode
        this.formItem.cf5 = data.data.userName
        this.formItem.cf6 = data.data.userCode
      })
    },
    // 获取详情
    getDetail () {
      this.$ajax({
        url: 'api-uum/module/pmd/person/inspect/getPmdReport',
        method: 'GET',
        data: {
          type: this.type,
          businessId: this.businessId
        }
      }).then(({data}) => {
        this.formItem = data.data
        this.pmdUserId = data.data.pmdUserId
        this.formItem.year = new Date(this.formItem.year + '')
        this.formItem.quarter = this.formItem.quarter + ''
        this.formItem.pmdUserName = this.formItem.userName
        this.formItem.pmdUserCode = this.formItem.userCode
        this.formItem.cf5 = this.formItem.userName
        this.formItem.cf6 = this.formItem.userCode
        this.formItem.redactTime = timeFormat(this.formItem.redactTime)
        this.formItem.inspectTime = timeFormat(this.formItem.inspectTime)
        if (this.formCode == 'FZDY0603') {
          this.formItem['02FZDY0603'] = [{
            fileId: this.formItem.fileId,
            fileName: this.formItem.fileName
          }]
        } else {
          this.formItem['05FZDY2102'] = [{
            fileId: this.formItem.fileId,
            fileName: this.formItem.fileName
          }]
        }
      })
    },
    // 生成表单和表格
    generatePage (index) {
      const list = this.formCode == 'FZDY0603' ? generateList.call(this, index) : twentyFirstGenerateList.call(this, index)
      list.forEach(item => {
        if (item.type === 'InputNumber' || item.type === 'Number') {
          this.$set(this.formItem, item[$field], null)
        }
      })
      return list
    },
    close () {
      this.$methods('Modal', 'close')
    },
    onHidden () {
      this.showModal = false
    },
    afterEnter () {
      this.showContent = true
    }
  },
  components: {
    ModalLayout,
    Modal,
    FormLayout
  }
}
</script>
