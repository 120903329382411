import { prefixPath } from '@/config'
import { timeFormat } from '@/utils'
import ButtonUpload from '@/components/button-upload'
export default function (index) {
  const config = this.dynamicList[index]
  if (!config) return []
  let _this = this
  const list = config.formPropertyList.map(item => {
    if (item.propertyConfig && item.propertyConfig.indexOf('session_listOrgId') > -1) {
      item.propertyConfig = item.propertyConfig.replace('session_listOrgId', this.orgId)
    }
    if (item.propertyConfig && item.propertyConfig.indexOf('prefixPath') > -1) {
      item.propertyConfig = item.propertyConfig.replace('prefixPath', 'api-uum/')
    }
    const props = JSON.parse(item.propertyConfig || '{}')
    if (item.widgetType === 'fileUpload') {
      props.data = props.data || {}
      props.data.groupId = this.groupId
      props.isShowFileList = true
    }
    //码表接口前缀
    if (props.treeType == 'tireTree') {
      if (props.url && props.url.indexOf('api-general') < 0 && props.params && props.params.dictCode) {
        props.url = 'api-general/module/portal/user/outGetRadioList'
        props.params.bizLineCode = this.bizLineCode
      }
    } else {
      if (props.url && props.url.indexOf('api-general') < 0 && props.params && props.params.dictCode) {
        props.url = 'api-general/module/portal/user/outGetList'
        props.params.bizLineCode = this.bizLineCode
      }
    }
    if (item.description) {
      props.extra = true
    }
    if (config.groupType == 1) { // 表单
      if (item.widgetType == 'fileUpload') { // 附件信息
        props.data.bizId = _this.pmdUserId || _this.$route.query.pmdUserId
      }
      if (item.propertyCode == 'userName') {
        props.isAllResult = props.isAllResult || true
        props.sendListData = (id, name) => {
          return {
            orgId: id,
            userName: name,
            drillType: 1
          }
        }
      }
      return item.isEnabled == 1 && item.widgetType != 'hidden' ? {
        title: item.displayName || item.propertyName,
        [$field]: item.propertyCode,
        type: item.widgetType,
        props: props,
        colSpan: item.colspan || '8',
        rules: item.isNotNull == 1 ? ['required'] : [],
        readOnly: item.isReadonly == 1,
        id: item.propertyId,
        propertyName: item.propertyName,
        isEnabled: item.isEnabled,
        itemDescribe: item.description,
        format: props.format || '',
        optionList: props.optionList || [],
        ref: item.propertyCode
      } : null
    } else { // 表格
      return item.widgetType != 'hidden' ? {
        nowType: config.displayName || config.groupName,
        showType: item.widgetType || '',
        title: item.displayName || item.propertyName,
        [$field]: item.propertyCode,
        width: item.width || '',
        props: props,
        render: (h, {row}) => {
          if (item.widgetType == 'DatePicker') {
            return h('span', timeFormat(row[item.propertyCode]), 'yyyy-MM-dd')
          } else if (item.propertyCode == 'fileName') {
            return h('span', {
              class: 'iv-pointer iv-hover-color iv-main-color',
              on: {
                click: () => {
                  let url = `${prefixPath}file/downloadFile?fileId=${row.fileId}` + '&access_token=' + sessionStorage.getItem('token') + '&currentAuthScopeId=' + sessionStorage.getItem('listOrgId')
                  window.open(url)
                }
              }
            }, row.fileName)
          } else if (item.propertyCode == 'configName') {
            if (row.isNotNull == 1) {
              return (<div>
                <span style="marginRight: '2px';color: red">*</span>
                <span>{row.configName}</span>
              </div>)
            } else {
              return h('span', row[item.propertyCode])
            }
          } else {
            return h('span', row[item.propertyCode])
          }
        }
      } : null
    }
  }).filter(v => v)
  
  if (config.groupCode == 'bisFileList') {
    list.push({
      title: '操作',
      [$field]: 'operate',
      width: 200,
      isOperate: true,
      render: (h, { row }) => {
        let edit = []
        edit.push(h(ButtonUpload, {
          props: {
            label: '附件上传',
            isText: true,
            url: 'api-uum/file/upload',
            accept: row.props?.accept || '.pdf,.jpg,.jpeg,.png,.gif'
          },
          style: {
            'padding': '0',
            'line-height': '20px',
            'margin-top': '8px',
            'display': 'inline-block'
          },
          class: ['iv-main-color', 'iv-hover-color'],
          on: {
            'on-upload-success': (file) => {
              if (!this.uploadFileList[row.propertyCode]) {
                this.uploadFileList[row.propertyCode] = []
              }
              this.formItem.bisFileList.map(item => {
                if (item.propertyCode == row.propertyCode) {
                  item.fileUpDate = timeFormat(file.response.data.uploadTime)
                  item.isYsc = '已上传'
                  item.fileId = file.response.data.fileId
                  item.fileName = file.response.data.fileName
                }
              })
              this.uploadFileList[row.propertyCode] = [{
                fileDefineId: file.response.data.fileDefineId,
                fileExt: file.response.data.fileExt,
                fileId: file.response.data.fileId,
                fileName: file.response.data.fileName,
                filesize: file.size,
                groupId: file.response.data.groupId,
                mimeType: file.response.data.mimeType,
                uploadTime: file.response.data.uploadTime,
                uploaduser: file.response.data.uploaduser
              }]
            }
          }
        }))
        if (row.downloadType == 1) {
          edit.push(h('span', {
            style: {
              'padding': '0',
              'line-height': '20px',
              'padding-left': '10px',
              'margin-top': '8px',
              'display': 'inline-block',
              'position': 'absolute',
              'cursor': 'pointer'
            },
            class: ['iv-main-color', 'iv-hover-color'],
            on: {
              'click': () => {
                this.formItem.bisFileList.map(item => {
                  if (item.propertyCode == row.propertyCode) {
                    this.downTemp('down', item)
                  }
                })
                // this.downTemp('see')
              }
            }
          }, '下载模板'))
        }
        return h('div', {
          style: 'position: relative;'
        }, edit)
      }
    })
  }
  return list
}
