import FZDY0101 from '@/views/recruiting-party-members/steps/first-step'
import FZDY0601 from '@/views/recruiting-party-members/steps/sixth-step.vue'
import FZDY0602 from '@/views/recruiting-party-members/steps/sixth-grzz-step.vue'
import FZDY1101 from '@/views/recruiting-party-members/steps/eleventh-step.vue'
import FZDY1401 from '@/views/recruiting-party-members/steps/fourteenth-step.vue'
import FZDY2102 from '@/views/recruiting-party-members/steps/twenty-one-step.vue'
import FZDY2201 from '@/views/recruiting-party-members/steps/twenty-two-step.vue'
import FZDY1001 from '@/views/recruiting-party-members/steps/tenth-step.vue'
import FZDY0603 from '@/views/recruiting-party-members/steps/thought-report/index.vue'
import FZDY2103 from '@/views/recruiting-party-members/steps/thought-report/index.vue'
import FZDY0501 from '@/views/recruiting-party-members/steps/fifth-step.vue'
export default {
  FZDY0101,
  FZDY0601,
  FZDY1101,
  FZDY1401,
  FZDY2102,
  FZDY2201,
  FZDY1001,
  FZDY0602,
  FZDY0603,
  FZDY0501,
  FZDY2103
}