export default function () {
  return [
    {
      [$field]: 'addS',
      type: 'add',
      label: '上传思想汇报'
    }, {
      [$field]: 'addP',
      type: 'add',
      label: '上传培养教育意见'
    }, {
      [$field]: 'addZ',
      type: 'add',
      label: '上传支部考察意见'
    }
  ]
}
