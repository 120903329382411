<template>
  <div class="iv-m-t-20">
    <div class="iv-text-right iv-diff-add iv-p-b-8">
      <Button type="add" @click="onOperatorClick('上传思想汇报')" class="iv-m-r-16">上传思想汇报</Button>
      <!-- <Button type="add" @click="onOperatorClick('上传培养教育意见')">上传培养教育意见</Button> -->
    </div>
    <DragTable
      ref="treeList"
      :showTree="false"
      :showEdit="false"
      :showDelete="false"
      :listData="listData"
      :url="listUrl"
      :columns="columns"
      :showSelect="false"
      :noSearch="true"
      :showPage="false"
      :span-method="handleSpan"
      :operationList="operationList"
    >
    </DragTable>
    <AddInspectUsers ref="addInspectUsers" @on-refresh="onRefresh"></AddInspectUsers>
    <AddThought ref="addThought" @on-refresh="onRefresh"></AddThought>
    <AddInspect ref="addInspect" @on-refresh="onRefresh"></AddInspect>
  </div>
</template>

<script>
import columns from './columns.js'
import DragTable from '@/components/drag-table-inline'
import operationList from './operationList'
import AddThought from '../modal-add-thought'
import AddInspectUsers from '../modal-add-inspect-users'
import AddInspect from '../add-inspect'

export default {
  props: ['pmdUserType', 'infoMation'],
  data () {
    return {
      type: this.$route.query.type,
      pmdUserId: this.$route.query.pmdUserId,
      listData: {
        pmdUserId: this.$route.query.pmdUserId
      },
      listUrl: '/api-uum/module/pmd/person/listPmdInspectSingle',
      operationList: [],
      formId: '',
      version: ''
    }
  },
  watch: {
    'pmdUserType' (val) {
      this.listData['pmdUserType'] = val
      this.$methods('treeList', 'forceRender')
    }
  },
  created () {
    this.formId = this.infoMation.stepFormState.formId
    this.version = this.infoMation.stepFormState.formVersion
    this.listData['pmdUserType'] = this.pmdUserType
    this.columns = columns.call(this)
    let _operationList = []
    if (this.type != 'see') {
      _operationList = operationList.call(this)
    }
    _operationList.push({
      [$field]: 'back',
      type: 'back',
      label: '返回'
    })
    this.operationList = _operationList
    this.$nextTick(() => {
      this.$methods('treeList', 'forceRender')
    })
  },
  provide () {
    return {
      expandParent: this.expandParent,
      interceptor: {
        response: {
          resolve: (data) => {
            let _data = []
            data.data.data.map(item => {
              if (item.pmdInspectSingleList && item.pmdInspectSingleList.length > 0) {
                item.pmdInspectSingleList.map((child, idx) => {
                  _data.push({
                    ...item,
                    ...child,
                    itemIndex: idx,
                    rowspan: item.pmdInspectSingleList.length,
                    businessId: item.businessId
                  })
                })
              } else {
                _data.push(item)
              }
            })
            data.data.data = [..._data]
            return data
          }
        }
      }
    }
  },
  methods: {
    //合并单元格
    handleSpan ({ row, column, rowIndex, columnIndex }) {
      if (column.type == 'index' || column.key == 'yearQuarter' || column.key == 'uploadTime' || column.key == 'createUserName' || column.key == 'operate') {
        if (row.rowspan > 1 && (columnIndex == 0 || columnIndex == 1 || columnIndex == 8 || columnIndex == 9 || columnIndex == 10)) {
          return {
            rowspan: row.itemIndex == 0 ? row.rowspan : 0,
            colspan: row.itemIndex == 0 ? 1 : 0
          }
        }
      }
    },
    onOperatorClick (label) {
      switch (label) {
        case '上传思想汇报':
          this.$nextTick(() => {
            this.$methods('addThought', 'open', {
              type: '1',
              pmdUserId: this.pmdUserId,
              pmdUserType: this.pmdUserType,
              formId: this.formId,
              version: this.version
            })
          })
          break
        case '上传培养教育意见':
          this.title = '上传培养教育意见'
          this.$methods('addInspectUsers', 'open', {
            type: '2',
            pmdUserId: this.pmdUserId,
            pmdUserType: this.pmdUserType,
            formId: this.formId,
            version: this.version
          })
          break
        case '返回':
          this.$router.go(-1)
          break
        default:
          break
      }
    },
    onRefresh () {
      this.$methods('treeList', 'forceRender')
    },
    expandParent (type, item) {
      if (type === 'see') { //查看详情
        this.$methods(item.typeCode == 1 ? 'addThought' : item.typeCode == 2 ? 'addInspectUsers' : 'addInspect', 'open', {
          type: item.typeCode,
          pmdUserId: item.pmdUserId,
          active: 'see',
          businessId: item.businessId,
          pmdUserType: this.pmdUserType,
          formId: this.formId,
          version: this.version
        })
      } else if (type === 'edit') {
        this.$methods(item.typeCode == 1 ? 'addThought' : 'addInspectUsers', 'open', {
          type: item.typeCode,
          pmdUserId: item.pmdUserId,
          active: 'edit',
          businessId: item.businessId,
          pmdUserType: this.pmdUserType,
          formId: this.formId,
          version: this.version
        })
      } else if (type === 'delete') {
        let templateCode = ''
        if (item.typeCode == 1) {
          templateCode = this.pmdUserType == '10' || this.pmdUserType == '20' ? '02FZDY0602' : '05FZDY2101'
        } else if (item.typeCode == 2) {
          templateCode = this.pmdUserType == '10' || this.pmdUserType == '20' ? '02FZDY0604' : '05FZDY2103'
        } else {
          templateCode = this.pmdUserType == '10' || this.pmdUserType == '20' ? '02FZDY0603' : '05FZDY2102'
        }
        this.$ajax({
          url: 'module/pmd/inspect/delPmdReport',
          method: 'GET',
          data: {
            businessId: item.businessId,
            type: item.typeCode,
            templateCode: templateCode
          }
        }).then(({ data }) => {
          this.$Message.success('删除成功')
          this.$methods('treeList', 'forceRender')
        })
      }
    },
    close () {
      this.$methods('Modal', 'close')
    },
    onHidden () {
      this.showModal = false
    },
    afterEnter () {
      this.showContent = true
    }
  },
  components: {
    DragTable,
    AddThought,
    AddInspectUsers,
    AddInspect
  }
}
</script>
