// import { prefixPath } from '@/config'
import ButtonUpload from '@/components/button-upload'
import { timeFormat } from '@/utils'
export default function (index) {
  const config = this.dynamicList[index]
  if (!config) return []
  this.tableRules[config.groupCode] = {}
  // let tireTreeMap = ['individualStatus']
  const list = config.formPropertyList.map(item => {
    if (item.propertyConfig && item.propertyConfig.indexOf('session_listOrgId') > -1) {
      item.propertyConfig = item.propertyConfig.replace('session_listOrgId', this.orgId)
    }
    if (item.propertyConfig && item.propertyConfig.indexOf('prefixPath') > -1) {
      item.propertyConfig = item.propertyConfig.replace('prefixPath', 'api-uum/')
    }
    const props = JSON.parse(item.propertyConfig || '{}')
    if (item.widgetType === 'fileUpload') {
      props.data = props.data || {}
      props.data.groupId = this.groupId
      props.isShowFileList = true
    }
    if (item.widgetType === 'InputNumber') {
      props.min = 0
    }
    if (item.widgetType === 'selectInputMember') {
      props.bizLineCode = props.bizLineCode || this.bizLineCode
      props.isAllResult = props.isAllResult || true
    }
    if (item.widgetType === 'universalTree' && props.treeParams) {
      if (props.treeParams.orgId === '') {
        props.treeParams.orgId = -1
        sessionStorage.setItem('orgId', -1)
      }
      props.treeParams.bizLineCode = props.treeParams.bizLineCode || this.bizLineCode
    }
    if (item.propertyCode === 'userName') {
      item.isReadonly = 1
    }
    //码表接口前缀
    if (props.treeType == 'tireTree') {
      if (props.url && props.url.indexOf('api-general') < 0 && props.params && props.params.dictCode) {
        props.url = 'api-general/module/portal/user/outGetRadioList'
        props.params.bizLineCode = this.bizLineCode
        sessionStorage.setItem('orgId', -1)
      }
    } else {
      if (props.url && props.url.indexOf('api-general') < 0 && props.params && props.params.dictCode) {
        props.url = 'api-general/module/portal/user/outGetList'
        props.params.bizLineCode = this.bizLineCode
      }
    }
    if (item.description) {
      props.extra = true
    }
    if (config.groupType == 1) { //表单
      if (item.widgetType == 'Input') {
        if (!props.maxlength) {
          props.maxlength = 500
        }
      }
      if (item.propertyCode == 'userName') {
        item.isNotNull = 2
      }
      if (item.widgetType == 'textarea') {
        if (!props.maxlength) {
          props.maxlength = 5000
        }
      }
      return item.isEnabled == 1 ? {
        type: item.widgetType || 'Input',
        title: item.displayName || item.propertyName,
        [$field]: item.propertyCode,
        colSpan: item.colspan || 8,
        description: item.description || '',
        readOnly: item.isReadonly == 1 ? true : false,
        props: props,
        width: item.width || 200,
        rules: item.isNotNull == 1 ? ['required'] : []
      } : null
    } else { //表格
      if (item.isNotNull == 1 && config.groupCode != 'bisFileList') {
        this.tableRules[config.groupCode][item.propertyCode] = [{
          required: true,
          message: item.displayName + '必填'
        }]
      }
      return item.isEnabled == 1 && item.widgetType != 'hidden' ? {
        renderHeader: item.isNotNull == 1 ? (h, {row}) => {
          return (<div>
            <span style="marginRight: '2px';color: red">*</span>
            <span>{item.displayName || item.propertyName}</span>
          </div>)
        } : null,
        showType: item.widgetType || '',
        title: item.displayName || item.propertyName,
        [$field]: item.propertyCode,
        width: item.width || (item.propertyCode == 'trainingClassType' ? 150 : null),
        minWidth: item.minWidth || null,
        readOnly: item.isReadonly == 1 ? true : false,
        props: props,
        render: (h, { row }) => {
          if (item.widgetType == 'DatePicker') {
            return h('datePicker', {
              props: {
                value: timeFormat(row[item.propertyCode])
              },
              style: 'width: 100%;',
              class: row?.errorQueue?.leaveTime == true ? 'red' : '',
              on: {
                'on-change': (e) => {
                  row[item.propertyCode] = e || ''
                  this.changeTableData(config.groupCode, row._index, item.propertyCode, row[item.propertyCode])
                }
              }
            })
          } else if (item.widgetType == 'Input') {
            return h('Input', {
              props: {
                value: row[item.propertyCode]
              },
              style: 'width: 100%;',
              on: {
                'on-blur': (e) => {
                  row[item.propertyCode] = e.currentTarget.value || ''
                  this.changeTableData(config.groupCode, row._index, item.propertyCode, row[item.propertyCode])
                }
              }
            })
          } else if (item.widgetType == 'InputNumber') {
            return h('InputNumber', {
              props: {
                placeholder: '',
                value: row[item.propertyCode] == null ? null : row[item.propertyCode],
                min: 0,
                precision: 0
              },
              style: 'width: 100%;',
              on: {
                'on-change': (e) => {
                  row[item.propertyCode] = e || 0
                  this.changeTableData(config.groupCode, row._index, item.propertyCode, row[item.propertyCode])
                }
              }
            })
          }  else if (item.widgetType == 'RemoteSelect' || item.widgetType == 'Select') {
            let optionList = item.widgetType == 'RemoteSelect' ? this.dataDictionary['GG74'] : props.optionList
            return h('Select', {
              props: {
                value: row[item.propertyCode] + '',
                disabled: item.propertyCode == 'trainingClassType'
              },
              style: 'width: 100%;',
              on: {
                'on-change': (e) => {
                  row[item.propertyCode] = e || ''
                  this.changeTableData(config.groupCode, row._index, item.propertyCode, row[item.propertyCode])
                }
              }
            }, optionList.map((item) => { // this.productTypeList下拉框里的data
              return h('Option', { // 下拉框的值
                props: {
                  label: item.label,
                  value: item.value
                }
              })
            }))
          } else if (item.widgetType == 'RemoteRadio') {
            return h('RadioGroup', {
              props: {
                value: row[item.propertyCode]
              },
              style: 'width: 100%;',
              on: {
                'on-change': (e) => {
                  row[item.propertyCode] = e || ''
                  this.changeTableData(config.groupCode, row._index, item.propertyCode, row[item.propertyCode])
                }
              }
            }, this.dataDictionary['GG73'].map((item) => {
              return h('Radio', {
                props: {
                  label: item.value,
                  // disabled: !_this.infoMation.active
                }
              }, item.label)
            }))
          }  else if (item.widgetType == 'fileUpload') {
            let edit = [], files = this.uploadFileList[item.propertyCode] ? this.uploadFileList[item.propertyCode] : [], _rowFiles = {}
            if (files.length > 0) {
              _rowFiles = files.find(_ => { return _.fileId == row['trainFileId'] })
              if (_rowFiles && _rowFiles.fileId) {
                this.changeTableData(config.groupCode, row._index, item.propertyCode + '_fileName', _rowFiles.fileName)
                this.changeTableData(config.groupCode, row._index, item.propertyCode + '_groupId', row[item.propertyCode])
                this.changeTableData(config.groupCode, row._index, 'trainFileId', _rowFiles.fileId)
                this.changeTableData(config.groupCode, row._index, item.propertyCode, _rowFiles.fileId)
              }
            }
            edit = [h('span', {
              style: {
                'cursor': 'pointer'
              },
              class: 'iv-m-t-12',
              on: {
                'click': () => {
                  let url = `api-uum/file/downloadFile?fileId=${row.trainFileId}` + '&access_token=' + sessionStorage.getItem('token') + '&currentAuthScopeId=' + sessionStorage.getItem('listOrgId')
                  window.open(url)
                }
              }
            }, _rowFiles?.fileName), h(ButtonUpload, {
              style: '',
              class: 'iv-m-t-12 iv-main-color',
              props: {
                label: '文件上传',
                isText: true,
                url: 'api-uum/file/upload',
                accept: props?.accept || '.pdf,.jpg,.jpeg,.png,.gif'
              },
              on: {
                'on-upload-success': (file) => {
                  let currentFile = file.response.data
                  this.changeTableData(config.groupCode, row._index, item.propertyCode + '_fileName', currentFile.fileName)
                  this.changeTableData(config.groupCode, row._index, item.propertyCode + '_groupId', currentFile.groupId)
                  this.changeTableData(config.groupCode, row._index, item.propertyCode, currentFile.fileId)
                  this.changeTableData(config.groupCode, row._index, 'trainFileId', currentFile.fileId)
                  this.onListUploadSuccess(config.groupCode, row._index, item.propertyCode, currentFile)
                  this.$Message.success('上传成功')
                },
                'on-input-clear': (com, file) => {
                  row[item.propertyCode + '_fileName'] = null
                  row[item.propertyCode + '_groupId'] = null
                  row[item.propertyCode] = null
                  this.changeTableData(config.groupCode, row._index, item.propertyCode + '_fileName', null)
                  this.changeTableData(config.groupCode, row._index, item.propertyCode + '_groupId', null)
                  this.changeTableData(config.groupCode, row._index, item.propertyCode, null)
                  this.changeTableData(config.groupCode, row._index, 'trainFileId', null)
                  this.onListUploadClear(config.groupCode, row._index, item.propertyCode, file)
                  this.$Message.success('删除成功')
                }
              }
            })]
            return h('div', edit)
          } else {
            return h('span', row[item.propertyCode])
          }
        }
      } : null
    }
  }).filter(v => v)
  if (config.groupCode == 'page03') {
    list.push({
      title: '操作',
      [$field]: 'operate',
      width: 50,
      isOperate: true,
      render: (h, { row }) => {
        return h('div',
          [
            h('span', {
              style: {
                'padding': '0',
                'line-height': '20px',
                'display': 'inline-block',
                'cursor': 'pointer'
              },
              class: ['iv-main-color', 'iv-hover-color'],
              on: {
                'click': () => {
                  this.formItem.page03.splice(row._index, 1)
                }
              }
            }, '删除')
          ])
      }
    })
  }
  return config.isEnabled == 1 ? list : []
}