<template>
  <div style="background: #fff;height: 100%;margin-top: -16px;">
    <div class="iv-message iv-main-width  iv-p-b-40 iv-p-t-40">
      <div class="iv-main-width iv-p-t-20">
        <Tab ref="tab" :value="activeIndex" :navList="navList" @on-click="tabClick"></Tab>
        <PmdUserList v-if="navList[activeIndex]" :pmdUserType="pmdUserType" :infoMation="infoMation"></PmdUserList>
        <!-- <component v-if="navList[activeIndex]" ref="learnContent" :is="pmdUserList" :name="navList[activeIndex].name" :pmdUserType="navList[activeIndex].props.pmdUserType" :activeIndex="navList[activeIndex].props.activeIndex" @onloaded="onloaded"></component> -->
      </div>
    </div>
  </div>
</template>
<script>
import Tab from '@/components/tab'
import { timeFormat } from '@/utils'
import PmdUserList from './list'
import computedHeight from '@/mixins/computed-height'
export default {
  name: 'thoughtReportTab',
  mixins: [computedHeight],
  props: {
    infoMation: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      showSearch: true,
      type: 0,
      name: '',
      show: false,
      tabList: [],
      list: [],
      userName: '',
      constTips: '',
      pmdUserType: this.$route.query.pmdUserType,
      stage: this.$route.query.stage,
      navList: [],
      activeIndex: '0'
    }
  },
  components: {
    Tab,
    timeFormat,
    PmdUserList
  },
  created () {
    if (this.stage) {
      // 看板内嵌页面时
      this.stage = this.$parent.stage
    }
    this.getList()
  },
  provide () {
    return {
      expandParent: this.expandParent
    }
  },
  methods: {
    getActiveIndex () {
      let zero = '0'
      if (this.pmdUserType) {
        let index = ['10', '20', '30', '40', '50'].indexOf(this.pmdUserType + '')
        let num = index == -1 ? 0 : index
        return num
      }
      return zero
    },
    tabClick (name) {
      console.log(name)
      this.activeIndex = name
      this.pmdUserType = this.navList[name].props.pmdUserType
    },
    getList () {
      this.activeIndex = this.activeIndex ? this.activeIndex : this.getActiveIndex()
      // 看板进入页面
      if (this.stage == 1) {
        this.activeIndex = '1'
        this.pmdUserType = '20'
      } else if (this.stage == 4) {
        this.activeIndex = '3'
        this.pmdUserType = '40'
      }
      this.navList = [
        {
          label: '入党申请人',
          name: '0',
          icon: 'appstore',
          props: {
            pmdUserType: '10'
          }
        },
        {
          label: '积极分子',
          name: '1',
          icon: 'appstore',
          props: {
            pmdUserType: '20'
          }
        },
        {
          label: '发展对象',
          name: '2',
          icon: 'appstore',
          props: {
            pmdUserType: '30'
          }
        },
        {
          label: '预备党员',
          name: '3',
          icon: 'appstore',
          props: {
            pmdUserType: '40'
          }
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.iv-message {
  // height: 100%;
  background-color: #fff;
  /deep/ .iv-drag-table {
    .ivu-table-row {
      .ivu-table-cell {
        font-size: 16px;
        color: rgba($color: #000000, $alpha: 0.65);
      }
    }
  }
}
.iv-p {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 400;
}
</style>