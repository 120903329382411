import { render, staticRenderFns } from "./fourteenth-step.vue?vue&type=template&id=8afce80a&scoped=true"
import script from "./fourteenth-step.vue?vue&type=script&lang=js"
export * from "./fourteenth-step.vue?vue&type=script&lang=js"
import style0 from "./fourteenth-step.vue?vue&type=style&index=0&id=8afce80a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8afce80a",
  null
  
)

export default component.exports