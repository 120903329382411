<template>
  <Modal
    loading
    transfer
    ref="Modal"
    @after-enter="afterEnter"
    @on-hidden="onHidden"
    :size="'bigLarge'"
    :title="title">
    <ModalLayout>
      <div>
        <AddThought ref="addThought" :params="params" @on-refresh="onRefresh"></AddThought>
      </div>
    </ModalLayout>
    <div slot="footer">
      <Button
        type="sure"
        noIcon
        class="uit-ok"
        v-if="active != 'see'"
        @click="onBtnEvents">
        <span>{{'提交'}}</span>
      </Button>
      <Button
        type="cancel"
        noIcon
        class="iv-m-l-8 uit-cancel"
        @click="close">
        <span>{{'取消'}}</span>
      </Button>
    </div>
  </Modal>
</template>

<script>
import AddThought from './add-thought'
import Modal from '@/components/modal'
import ModalLayout from '@/components/modal/layout'
export default {
  name: 'addThoughtModal',
  data () {
    return {
      title: '上传思想汇报',
      showModal: false,
      showContent: false,
      active: true,
      params: {}
    }
  },
  methods: {
    onRefresh () {
      this.close()
      this.$emit('on-refresh')
    },
    open (params) {
      this.active = params.active
      this.params = params
      this.$methods('Modal', 'open')
    },
    close () {
      this.$methods('Modal', 'close')
    },
    onHidden () {
      this.showModal = false
    },
    afterEnter () {
      this.showContent = true
    },
    onBtnEvents () {
      this.$methods('addThought', 'onBtnEvents')
    }
  },
  components: {
    ModalLayout,
    Modal,
    AddThought
  }
}
</script>
