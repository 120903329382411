<template>
  <div style="background: #fff;height: 100%;margin-top: -16px;">
    <div class="iv-main-width  iv-p-b-40 iv-p-t-40">
    <!-- <h1 class="iv-fs-24 iv-fc-85 iv-vertical-line">{{ infoMation.stepDescription }}</h1> -->
    <div class="iv-main-width">
      <template v-for="(item, index) in pageafterList">
        <components
          :key="index"
          :infoMation="item"
          :readOnly="true"
          :is="item.stepFormState.formCode">
        </components>
      </template>
      <LayoutTitle :title="item.groupName" v-for="(item, index) in dynamicList" :key="item.groupId">
      <FormLayout
        v-if="item.formPropertyList.length && item.groupType == 1"
        ref="fourteenthStep" 
        :readOnly="readOnly"
        :model="formItem[item.groupCode]"
        :list="generatePage(index)"
        @on-selectree-change="onSelectreeChange"
        @on-change-tree="onChangeTreeValue"
        >
      </FormLayout>
      <DragTable
        class="iv-m-t-24"
        noSearch
        noUrl
        v-if="item.groupType != 1"
        :showEdit="false"
        :showDelete="false"
        :showSelect="false"
        :showPage="false"
        ref="DragTable" 
        :immediateForce="true" 
        :columns="generatePage(index)" 
        :tableData="formItem[item.groupCode]">
      </DragTable>
    </LayoutTitle>
    <FooterOperator>
      <!-- <Button class="iv-m-r-8"  type="submit" @click="click" v-if="infoMation.active">提交</Button>
      <Button class="iv-m-r-8"  type="save" @click="save" v-else>保存</Button> -->
      <Button class="iv-m-r-8"  type="back" @click="back">返回</Button>
    </FooterOperator>
    </div>
  </div>
  </div>
</template>

<script>
import FormLayout from '@/components/layout/form'
import LayoutTitle from '@/components/layout/layout-title'
import fifth from './configs/fifth'
import FooterOperator from '@/components/footer-operator'
import { timeFormat } from '@/utils'
import DragTable from '@/components/drag-table-inline'
// import { prefixPath } from '@/config'
import importComponents from './combination'
// import { cloneDeep } from 'lodash'
export default {
  components: {
    DragTable,
    FormLayout,
    LayoutTitle,
    FooterOperator,
    importComponents
  },
  props: {
    infoMation: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      formItem: {
        page01: {},
        bisFileList: []
      },
      pageafterList: [],
      dynamicList: [],
      readOnly: false,
      version: this.$route.query.version,
      formId: this.$route.query.formId,
      orgId: sessionStorage.getItem('orgId'),
      bizLineCode: 'YWX01',
      pmdUserId: this.$route.query.pmdUserId, //YWX01
      bisFileList: [],
      configFileList: [],
      uploadFileList: [],
      content: this.$route.query.title,
    }
  },
  beforeCreate () {
    this.$options.propsData.infoMation.beforeStepFormStates.map(item => {
      this.$options.components[item.formCode] = importComponents[item.formCode]
    })
  },
  created () {
    this.formId = this.infoMation.stepFormState.formId
    this.version = this.infoMation.stepFormState.formVersion
    this.pageafterList = this.changeList(this.infoMation.beforeStepFormStates || [])
    this.getDynamicFormInfo()
  },
  methods: {
    changeList (list) {
      let formDataList = []
      list.map(item => {
        item.noShowList = true
        formDataList.push({
          stepFormState: item
        })
      })
      return formDataList
    },
    generatePage (index) {
      return fifth.call(this, index)
    },
    //获取动态表单接口
    getDynamicFormInfo () {
      return new Promise((resolve, reject) => {
        this.$ajax({
          url: 'api-general/module/dynamicform/form/getFormStructure',
          method: 'get',
          data: {
            formId: this.formId,
            version: this.version
          }
        }).then(({data}) => {
          this.recommendFormList = []
          this.dynamicList = []
          const arr = data.data.formGroupList.filter(item => item.isEnabled == 1)
          arr.forEach((item) => {
            this.dynamicList.push(item)
          })
          this.$nextTick(() => {
            if (this.pmdUserId) {
              this.getDetail()
            }
          })
          resolve()
        })
      })
    },
    getDetail () {
      this.$ajax({
        url: '/api-uum/module/pmd/person/getPmd05',
        method: 'GET',
        data: {
          pmdUserId: this.pmdUserId
        }
      }).then(data => {
        this.formItem.pmdUserId = data.data.data.pmdUserId
        this.formItem.page01 = data.data.data.page01
        this.formItem.page01.applyTime = timeFormat(data.data.data.page01.applyTime)
        this.formItem.page01.activistDate = timeFormat(data.data.data.page01.activistDate)
        this.formItem.page01.developDate = timeFormat(data.data.data.page01.developDate)
        this.uploadFileList = data.data.data.bisFileList
        this.formItem.page02 = data.data.data.page01.pmdAssignCultivateUserList || []
        this.formItem.page02.map(item => {
          item.code = 'page02'
        })
      })
    },
    onSelectreeChange (list, type) {
      this.formItem.individualStatus = list[0].data.itemCode
    },
    onChangeTreeValue (res, r, item) {
      if (r == 'orgName') {
        this.orgInfo = res[0]
      }
    },
    downTemp (type, data) {
      this.$ajax({
        url: 'api-uum/module/pmd/person/getAttacheFile',
        method: 'GET',
        data: {
          attachFileConfigId: data.attachFileConfigId
        }
      }).then(({data}) => {
        let fileId = data.data.fileId
        let url = `api-uum/file/downloadFile?fileId=${fileId}` + '&access_token=' + sessionStorage.getItem('token') + '&currentAuthScopeId=' + sessionStorage.getItem('listOrgId')
        window.open(url)
      })
    },
    // 附件遍历
    bisFileListFor () {
      this.formItem.bisFileList.forEach(item => {
        if (item.isNotNull == 1 && !this.uploadFileList[item.propertyCode]?.length) {
          this.currNullFileName = item.configName
          this.isSubmit = false
          throw new Error('')
        }
      })
    },
    // 点击提交，非编辑修改接口
    click () {
      let result
      Array.from(this.$refs.fourteenthStep)[0].validate((valid) => {
        result = valid
      })
      if (!result) return this.$Message.error('表单校验失败')
      this.isSubmit = true
      try {
        this.bisFileListFor()
      } catch (e) {
        console.log()
      }
      if (!this.isSubmit) return this.$Message.error(`请上传${this.currNullFileName || ''}附件！`)
      this.$ajax({
        url: '/api-uum/module/pmd/person/savePmd14',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        disabledData: true,
        data: {
          ...this.formItem,
          page01: {
            activistDate: this.formItem.page01.activistDate ? timeFormat(this.formItem.page01.activistDate) : '',
            applicationSubmitTime: this.formItem.page01.applicationSubmitTime ? timeFormat(this.formItem.page01.applicationSubmitTime) : '',
            applyTime: this.formItem.page01.applyTime ? timeFormat(this.formItem.page01.applyTime) : '',
            developDate: this.formItem.page01.developDate ? timeFormat(this.formItem.page01.developDate) : ''
          },
          bisFileList: this.uploadFileList,
          orgId: sessionStorage.getItem('orgId'),
          pmdUserId: this.formItem.pmdUserId || ''
        }
      }).then(data => {
        this.$Message.success('提交成功')
        this.back()
      })
    },
    // 可编辑的接口
    save () {
      let result
      Array.from(this.$refs.fourteenthStep)[0].validate((valid) => {
        result = valid
      })
      if (!result) return this.$Message.error('表单校验失败')
      this.isSubmit = true
      try {
        this.bisFileListFor()
      } catch (e) {
        console.log()
      }
      if (!this.isSubmit) return this.$Message.error(`请上传${this.currNullFileName || ''}附件！`)
      this.$ajax({
        url: '/api-uum/module/pmd/person/savePmd14Save',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        disabledData: true,
        data: {
          ...this.formItem,
          page01: {
            activistDate: this.formItem.page01.activistDate ? timeFormat(this.formItem.page01.activistDate) : '',
            applicationSubmitTime: this.formItem.page01.applicationSubmitTime ? timeFormat(this.formItem.page01.applicationSubmitTime) : '',
            applyTime: this.formItem.page01.applyTime ? timeFormat(this.formItem.page01.applyTime) : '',
            developDate: this.formItem.page01.developDate ? timeFormat(this.formItem.page01.developDate) : ''
          },
          bisFileList: this.uploadFileList,
          orgId: sessionStorage.getItem('orgId'),
          pmdUserId: this.formItem.pmdUserId || ''
        }
      }).then(data => {
        this.$Message.success('保存成功')
        this.back()
      })
    },
    back () {
      this.$router.push({
        path: 'recruitingPartyMembersProgressKanban'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/.iv-main-background {
  background: #fff;
  height: 100%;
}
</style>