import operate from './operate'
import { timeFormat } from '@/utils'
import { prefixPath } from '@/config'

export default function () {
  return [
    {
      'title': '序号',
      width: 60,
      type: 'index'
    },
    {
      title: '年度/季度',
      [$field]: 'yearQuarter'
    },
    {
      title: '编写日期',
      [$field]: 'redactTime',
      width: 100,
      'render' (c, {row}) {
        return c('span', timeFormat(row.redactTime))
      }
    },
    {
      title: '提交阶段',
      [$field]: 'submitStageName',
      width: 120
    },
    {
      title: '类型',
      [$field]: 'typeName'
    },
    {
      title: '附件信息',
      [$field]: 'fileName',
      'render' (c, {row}) {
        return c('span', {
          class: 'iv-pointer iv-hover-color iv-main-color',
          on: {
            click: () => {
              let url = `${prefixPath}file/downloadFile?fileId=${row.fileId}` + '&access_token=' + sessionStorage.getItem('token') + '&currentAuthScopeId=' + sessionStorage.getItem('listOrgId')
              window.open(url)
            }
          }
        }, row.fileName)
      }
    },
    {
      title: '所属党支部',
      [$field]: 'orgName'
    },
    {
      title: '提交日期',
      [$field]: 'submitDate',
      width: 100,
      'render' (c, {row}) {
        return c('span', timeFormat(row.submitDate))
      }
    },
    {
      title: '上传人',
      [$field]: 'createUserName'
    },
    {
      title: '上传日期',
      [$field]: 'uploadTime',
      width: 100,
      'render' (c, {row}) {
        return c('span', timeFormat(row.uploadTime))
      }
    },
    {
      title: '操作',
      [$field]: 'operate',
      width: 160,
      isOperate: true,
      slotComponent: operate
    }
  ]
}
