<template>
  <div style="background: #fff;height: 100%;margin-top: -16px;">
    <div class="iv-main-width  iv-p-b-40 iv-p-t-40">
    <!-- <h1 v-if="!readOnly" class="iv-fs-24 iv-fc-85 iv-vertical-line">{{ infoMation.stepDescription || '申请入党' }}</h1> -->
    <div class="iv-main-width">
      <LayoutTitle :title="item.groupName" v-for="(item, index) in dynamicList" :key="item.groupId">
      <FormLayout
        v-if="item.formPropertyList.length && item.groupType == 1"
        ref="firstStep" 
        :readOnly="readOnly"
        :model="formItem[item.groupCode]"
        :list="generatePage(index)"
        @on-selectree-change="onSelectreeChange"
        @on-change-tree="onChangeTreeValue"
        >
      </FormLayout>
      <DragTable
        class="iv-m-t-24"
        noSearch
        v-if="item.groupType != 1"
        :showEdit="false"
        :showDelete="false"
        :showSelect="false"
        :showPage="false"
        ref="DragTable" 
        :immediateForce="true" 
        :columns="generatePage(index)" 
        :tableData="formItem[item.groupCode]">
      </DragTable>
    </LayoutTitle>
    <FooterOperator>
      <Button class="iv-m-r-8"  type="submit" @click="click" v-if="info.active">提交</Button>
      <Button class="iv-m-r-8"  type="save" @click="save" v-else-if="isEdit">保存</Button>
      <Button class="iv-m-r-8"  type="back" @click="back">返回</Button>
    </FooterOperator>
    </div>
  </div>
  </div>
</template>

<script>
import FormLayout from '@/components/layout/form'
import LayoutTitle from '@/components/layout/layout-title'
import first from './configs/first'
import FooterOperator from '@/components/footer-operator'
import { timeFormat } from '@/utils'
import DragTable from '@/components/drag-table-inline'
// import { prefixPath } from '@/config'
// import { cloneDeep } from 'lodash'
export default {
  components: {
    DragTable,
    FormLayout,
    LayoutTitle,
    FooterOperator
  },
  props: {
    infoMation: {
      type: Object,
      default () {
        return {}
      }
    },
    readOnly: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      formItem: {
        baseInformation: {},
        page01: {},
        bisFileList: []
      },
      dynamicList: [],
      version: '',
      formId: '',
      orgId: sessionStorage.getItem('orgId'),
      bizLineCode: 'YWX01',
      pmdUserId: this.$route.query.pmdUserId, //YWX01
      bisFileList: [],
      configFileList: [],
      uploadFileList: {},
      orgInfo: {},
      info: {
        active: null
      },
      title: this.$route.query.title,
      isEdit: false
    }
  },
  created () {
    if (this.infoMation.active == undefined) {
      this.info.active = true
    } else {
      this.info = this.infoMation
    }
    this.formId = this.infoMation?.stepFormState?.formId ? this.infoMation.stepFormState.formId : this.$route.query.formId
    this.version = this.infoMation?.stepFormState?.formVersion ? this.infoMation.stepFormState.formVersion : this.$route.query.version
    this.getFileconfiglist()
  },
  methods: {
    generatePage (index) {
      let active = ''
      if (this.$parent.name == 'stepList') {
        active = this.$parent.getSecondActive() ? 0 : 1
      }
      if (this.infoMation.active) {
        this.isEdit = true
      } else {
        this.isEdit = active == 0
      }
      let list = first.call(this, index)
      if (this.isEdit && index == 1) {
        list.map(item => {
          if (item.key == 'applyTime') {
            item.isReadOnly = 0
            item.readOnly = false
          } else {
            item.isReadOnly = 1
            item.readOnly = true
          }
        })
      }
      return list
    },
    getPersonalInfo () {
      this.$ajax({
        url: '/api-uum/uuminfo/getUserInfo',
        method: 'get',
      }).then((data) => {
        this.formItem.baseInformation = data.data.data
        this.formItem.baseInformation.gender = this.formItem.baseInformation.sex == '男'? '1': '2'
        this.formItem.baseInformation.nation = this.formItem.baseInformation.nationnality
        this.formItem.baseInformation.education = this.formItem.baseInformation.highestEducation
        this.formItem.baseInformation.address = this.formItem.baseInformation.familyAddress
        this.formItem.baseInformation.birthday = timeFormat(this.formItem.baseInformation.birthday)
        this.formItem.baseInformation.joinWorkDate = timeFormat(this.formItem.baseInformation.joinWorkDate)
        this.formItem.baseInformation.gender = this.formItem.baseInformation.gender + ''
        this.formItem.page01.applyTime = timeFormat(new Date())
        this.formItem.orgId = data.data.data.orgId
        this.formItem.bPayPlace = data.data.data.bPayPlace
        this.getOrg()
      })
    },
    getOrg () {
      this.$ajax({
        url: 'api-uum/module/pmd/person/getOrg',
        method: 'GET',
        data: {
          orgId: this.formItem.orgId,
          bPayPlace: this.formItem.bPayPlace
        }
      }).then(data => {
        let res = data.data.data
        this.formItem.page01.orgId = res ? res.orgId : ''
        this.formItem.page01.orgName = res ? res.orgName : ''
        this.$forceUpdate()
      })
    },
    getFileconfiglist () {
      this.$ajax({
        url: 'api-uum/module/pmd/person/fileconfiglist',
        method: 'GET',
        data: {
          configDesc: '01-递交入党申请书'
        }
      }).then(data => {
        this.configFileList = data.data.data
        this.getDynamicFormInfo()
      })
    },
    //获取动态表单接口
    getDynamicFormInfo () {
      return new Promise((resolve, reject) => {
        this.$ajax({
          url: 'api-general/module/dynamicform/form/getFormStructure',
          method: 'get',
          data: {
            formId: this.formId,
            version: this.version
          }
        }).then(({data}) => {
          this.recommendFormList = []
          this.dynamicList = []
          const arr = data.data.formGroupList.filter(item => item.isEnabled == 1)
          arr.forEach((item) => {
            if (this.infoMation?.stepFormState?.noShowList) {
              if (!this.infoMation.stepFormState.noShowList) { 
                if (item.groupCode === 'bisFileList') {
                  item.formPropertyList.push({
                    displayName: '附件名称',
                    propertyCode: 'configName',
                    isEnabled: 1,
                  }, {
                    displayName: '附件',
                    propertyCode: 'fileName',
                    isEnabled: 1
                  }, {
                    displayName: '是否已上传',
                    propertyCode: 'isYsc',
                    isEnabled: 1,
                    width: 100
                  }, {
                    displayName: '上传时间',
                    propertyCode: 'fileUpDate',
                    widgetType: 'DatePicker',
                    isEnabled: 1,
                    width: 120
                  })
                }
                // 业务存储附件
                if (item.groupCode === 'bisFileList') {
                  let fileList = []
                  this.formItem[item.groupCode] = []
                  item.formPropertyList.map(i => {
                    if (i.widgetType === 'hidden') {
                      this.$set(this.bisFileList, i.propertyCode, []) // 初始化code为空数组
                      let propertyConfig = JSON.parse(i.propertyConfig) || {}
                      fileList.push({
                        ...i,
                        code: 'bisFileList',
                        props: propertyConfig,
                        attachFileConfigId: propertyConfig.data.attachFileConfigId,
                        downloadType: propertyConfig.downloadType,
                        fileId: '',
                        fileName: ''
                      })
                    }
                  })
                  fileList.forEach(item => {
                    this.configFileList.forEach(val => item.propertyCode === val.configCode && this.formItem.bisFileList.push({ ...item, ...val }))
                  })
                }
                this.dynamicList.push(item)
              } else {
                if (item.isEnabled == 1 && item.groupCode == 'baseInformation') { 
                  this.dynamicList.push(item)
                }
              }
            } else {
              if (item.groupCode === 'bisFileList') {
                item.formPropertyList.push({
                  displayName: '附件名称',
                  propertyCode: 'configName',
                  isEnabled: 1,
                }, {
                  displayName: '附件',
                  propertyCode: 'fileName',
                  isEnabled: 1
                }, {
                  displayName: '是否已上传',
                  propertyCode: 'isYsc',
                  isEnabled: 1,
                  width: 100
                }, {
                  displayName: '上传时间',
                  propertyCode: 'fileUpDate',
                  widgetType: 'DatePicker',
                  isEnabled: 1,
                  width: 120
                })
              }
              // 业务存储附件
              if (item.groupCode === 'bisFileList') {
                let fileList = []
                this.formItem[item.groupCode] = []
                item.formPropertyList.map(i => {
                  if (i.widgetType === 'hidden') {
                    this.$set(this.bisFileList, i.propertyCode, []) // 初始化code为空数组
                    let propertyConfig = JSON.parse(i.propertyConfig) || {}
                    fileList.push({
                      ...i,
                      code: 'bisFileList',
                      props: propertyConfig,
                      attachFileConfigId: propertyConfig.data.attachFileConfigId,
                      downloadType: propertyConfig.downloadType,
                      fileId: '',
                      fileName: ''
                    })
                  }
                })
                fileList.forEach(item => {
                  this.configFileList.forEach(val => item.propertyCode === val.configCode && this.formItem.bisFileList.push({ ...item, ...val }))
                })
              }
              this.dynamicList.push(item)
            }
          })
          this.$nextTick(() => {
            if (this.pmdUserId) {
              this.getDetail()
            } else {
              this.getPersonalInfo()
            }
          })
          resolve()
        })
      })
    },
    getDetail () {
      this.$ajax({
        url: '/api-uum/module/pmd/person/getPmd01',
        method: 'GET',
        data: {
          pmdUserId: this.pmdUserId
        }
      }).then(data => {
        this.formItem.pmdUserId = data.data.data.pmdUserId
        this.formItem.baseInformation = data.data.data.baseInformation
        this.formItem.page01 = data.data.data.page01
        this.formItem.baseInformation.birthday = timeFormat(this.formItem.baseInformation.birthday)
        this.formItem.baseInformation.joinWorkDate = timeFormat(this.formItem.baseInformation.joinWorkDate)
        this.formItem.baseInformation.gender = this.formItem.baseInformation.gender + ''
        this.formItem.page01.applyTime = timeFormat(data.data.data.page01.applyTime)
        sessionStorage.setItem('listOrgName', data.data.data.baseInformation.orgName)
        // 附件信息
        this.uploadFileList = data.data.data?.bisFileList ? data.data.data?.bisFileList : {} // 保存附件信息
        if (this.formItem.bisFileList && this.formItem.bisFileList.length && this.uploadFileList) {
          this.formItem.bisFileList.map(item => {
            item.fileId = this.uploadFileList[item.propertyCode] ? this.uploadFileList[item.propertyCode][0]?.fileId : ''
            item.fileName = this.uploadFileList[item.propertyCode] ? this.uploadFileList[item.propertyCode][0]?.fileName : ''
            item.isYsc = this.uploadFileList[item.propertyCode][0]?.fileName ? '已上传' : '未上传'
            item.fileUpDate = timeFormat(this.uploadFileList[item.propertyCode][0]?.uploadTime)
          })
        }
      })
    },
    onSelectreeChange (list, type) {
      this.formItem.individualStatus = list[0].data.itemCode
    },
    onChangeTreeValue (res, r, item) {
      if (r == 'orgName') {
        this.orgInfo = res[0]
        this.formItem.page01.orgId = res[0].id
        this.formItem.page01.orgName = res[0].title
        this.$forceUpdate()
      }
    },
    downTemp (type, data) {
      this.$ajax({
        url: '/api-uum/module/pmd/person/getAttacheFile',
        method: 'GET',
        data: {
          attachFileConfigId: data.attachFileConfigId
        }
      }).then(({data}) => {
        let fileId = data.data.fileId
        let url = `api-uum/file/downloadFile?fileId=${fileId}` + '&access_token=' + sessionStorage.getItem('token') + '&currentAuthScopeId=' + sessionStorage.getItem('listOrgId')
        window.open(url)
      })
    },
    // 保存
    onSave () {
      return new Promise((resolve, reject) => { 
        let result
        Array.from(this.$refs.firstStep)[1].validate((valid) => {
          result = valid
        })
        if (!result) return this.$Message.error('表单校验失败')
        this.$ajax({
          url: '/api-uum/module/pmd/person/savePmd01',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          disabledData: true,
          data: {
            baseInformation: {
              ...this.formItem.baseInformation,
              birthday: this.formItem.baseInformation.birthday ? timeFormat(this.formItem.baseInformation.birthday) : '',
              joinWorkDate: this.formItem.baseInformation.joinWorkDate ? timeFormat(this.formItem.baseInformation.joinWorkDate) : ''
            },
            page01: {
              ...this.formItem.page01,
              orgId: this.orgInfo?.id,
              orgName: this.orgInfo?.title,
              applyTime: this.formItem.page01.applyTime ? timeFormat(this.formItem.page01.applyTime) : ''
            },
            bisFileList: this.uploadFileList,
            orgId: this.orgId,
          }
        }).then(data => {
          this.pmdUserId = data.data.data.pmdUserId
          this.formItem.pmdUserId = this.pmdUserId
          resolve(data.data)
        })
      })
      
    },
    // 点击提交，非编辑修改接口
    click () {
      let result
      Array.from(this.$refs.firstStep)[1].validate((valid) => {
        result = valid
      })
      if (!result) return this.$Message.error('表单校验失败')
      this.isSubmit = true
      try {
        this.formItem.bisFileList.forEach(item => {
          if (item.isNotNull == 1 && !this.uploadFileList[item.propertyCode]?.length) {
            this.currNullFileName = item.configName
            this.isSubmit = false
            throw new Error('')
          }
        })
      } catch (e) {
        console.log()
      }
      if (!this.isSubmit) return this.$Message.error(`请上传${this.currNullFileName || ''}附件！`)
      this.$ajax({
        url: '/api-uum/module/pmd/person/savePmd01',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        disabledData: true,
        data: {
          baseInformation: {
            ...this.formItem.baseInformation,
            birthday: this.formItem.baseInformation.birthday ? timeFormat(this.formItem.baseInformation.birthday) : '',
            joinWorkDate: this.formItem.baseInformation.joinWorkDate ? timeFormat(this.formItem.baseInformation.joinWorkDate) : ''
          },
          page01: {
            ...this.formItem.page01,
            orgId: this.formItem.page01.orgId || this.orgInfo?.id,
            orgName: this.formItem.page01.orgName || this.orgInfo?.title,
            applyTime: this.formItem.page01.applyTime ? timeFormat(this.formItem.page01.applyTime) : ''
          },
          stepEventCode: 'SUBMIT',
          bisFileList: this.uploadFileList,
          orgId: this.orgId,
          pmdUserId: this.formItem.pmdUserId || ''
        }
      }).then(data => {
        this.$Message.success('提交成功')
        this.back()
      })
    },
    // 可编辑的接口
    save () {
      let result
      Array.from(this.$refs.firstStep)[1].validate((valid) => {
        result = valid
      })
      if (!result) return this.$Message.error('表单校验失败')
      this.isSubmit = true
      try {
        this.formItem.bisFileList.forEach(item => {
          if (item.isNotNull == 1 && !this.uploadFileList[item.propertyCode]?.length) {
            this.currNullFileName = item.configName
            this.isSubmit = false
            throw new Error('')
          }
        })
      } catch (e) {
        console.log()
      }
      if (!this.isSubmit) return this.$Message.error(`请上传${this.currNullFileName || ''}附件！`)
      this.$ajax({
        url: '/api-uum/module/pmd/person/savePmd01Save',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        disabledData: true,
        data: {
          baseInformation: {
            ...this.formItem.baseInformation,
            birthday: this.formItem.baseInformation.birthday ? timeFormat(this.formItem.baseInformation.birthday) : '',
            joinWorkDate: this.formItem.baseInformation.joinWorkDate ? timeFormat(this.formItem.baseInformation.joinWorkDate) : ''
          },
          page01: {
            ...this.formItem.page01,
            applyTime: this.formItem.page01.applyTime ? timeFormat(this.formItem.page01.applyTime) : ''
          },
          bisFileList: this.uploadFileList,
          orgId: this.orgId,
          pmdUserId: this.formItem.pmdUserId || ''
        }
      }).then(data => {
        this.$Message.success('保存成功')
        this.back()
      })
    },
    back () {
      this.$router.push({
        path: 'recruitingPartyMembersProgressKanban'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/.iv-main-background {
  background: #fff;
  height: 100%;
}
</style>