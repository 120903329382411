export default {
  provide () {
    return {
      emitSyncHeight: this.emitSyncHeight,
      emitTabClick: this.emitTabClick
    }
  },
  mounted () {
    if (this.$$autoHeight) return
    window.addEventListener('resize', this.emitSyncHeight)
    this.$on('hook:destroyed', () => {
      window.removeEventListener('resize', this.emitSyncHeight)
    })
  },
  methods: {
    emitSyncHeight (data, vm) {
      if ((vm.$attrs.autoHeight !== void 0 && vm.$attrs.autoHeight) || this.autoHeight) return
      //if (this.$$autoHeight) return
      vm = vm || this.vm
      this.vm = vm
      computedHeight.call(this)
      setTimeout(computedHeight.bind(this), 600)
      function computedHeight () {
        Promise.resolve().then(() => {
          //获取树高度
          let tableWrappers = this.$el.querySelectorAll('.ivu-table-body'),
            tableWrapper = tableWrappers[tableWrappers.length - 1]
          if (!tableWrapper) return
          // pageBottom 分页底部去掉的padding-bottom数值
          let skinl = localStorage.getItem('skinLayout') || this.$api.cookies.get('skinLayout') || 'normal'
          // let pageBottom = skinl == 'compact' ? 34 : 24
          let pageBottom = skinl == 'compact' ? 60 : 42
          let pageHeight = vm && vm.showPage ? 59 : -16,
            reduHeight = tableWrapper.getBoundingClientRect().top + pageHeight - pageBottom,
            height = window.innerHeight - reduHeight
          vm && vm.setHeight(height)
          if (tableWrapper.offsetWidth >= (tableWrapper.querySelector('table') || {}).offsetWidth) {
            tableWrapper.style.overflowX = 'hidden'
            return
          }
          tableWrapper.style.overflowX = 'auto'
        }).finally(() => {
          document.body.style.overflow = 'auto'
        })
      }
    },
    emitTabClick (item, index) {
      let showPage = item?.layoutProps?.showPage
      this.$$showPage = showPage === void 0 ? true : showPage
      this.$$autoHeight = item?.layoutProps?.autoHeight
    }
  }
}
