<template>
  <div>
    <FormLayout
    v-if="!stepCode"
      class="iv-m-t-20"
      ref="ComputedFormLayout"
      :rules="rules"
      :isShowSetting="false"
      :list="layoutList"
      :model="formItem"
      :readOnly="active == 'see'"
      @on-upload-success="uploadSuccess(...arguments)"
      @on-input-clear="uploadClear"
      @on-download-template="downTemp"
      >
    </FormLayout>
    <div class="iv-pseronal iv-p-t-40 iv-p-b-40" v-else>
      <div class="iv-main-width iv-p-b-40">
        <LayoutTitle title="上传思想汇报">
          <FormLayout
            class="iv-m-t-20"
            ref="ComputedFormLayout"
            :rules="rules"
            :isShowSetting="false"
            :list="layoutList"
            :model="formItem"
            :readOnly="active == 'see'"
            @on-upload-success="uploadSuccess(...arguments)"
            @on-input-clear="uploadClear"
            @on-download-template="downTemp"
            >
          </FormLayout>
        </LayoutTitle>
      </div>
      <FooterOperator>
        <div class="iv-text-center">
          <Button type="save" @click="onBtnEvents" v-if="!businessId">保存</Button>
          <Button type="back" @click="close" 
        class="iv-m-l-8 uit-cancel">返回</Button>
        </div>
      </FooterOperator>
    </div>
  </div>
</template>

<script>
import FormLayout from '@/components/layout/form'
import LayoutTitle from '@/components/layout/layout-title'
import FooterOperator from '@/components/footer-operator'
import generateList from '../sixth.js'
import twentyFirstGenerateList from '../twenty-first.js'
import validate from '@/mixins/validate'
import { timeFormat, setMinDate } from '@/utils'
// import { prefixPath } from '@/config'
export default {
  name: 'addThought',
  mixins: [validate],
  data () {
    return {
      layoutList: [],
      formItem: {
        createUserName: sessionStorage.getItem('userName'),
        uploadTime: timeFormat(new Date()),
        pmdUserName: '',
        pmdUserCode: '',
        '02FZDY0602': [],
        '05FZDY2101': [],
        //作为单独页面个人中心，会限制死季度和年限
        year: this.$route.query.year,
        quarter: this.$route.query.quarter,
        cf1: '',
        cf2: ''
      },
      businessId: '',
      type: '',
      tableRules: {},
      active: this.$route.query.active,
      pmdUserType: this.$route.query.pmdUserType,
      formId: '',
      title: '上传思想汇报',
      version: '',
      formCode: '',
      stepCode: this.$route.query.stepCode,
      uploadFileList: {}
    }
  },
  created () {
    this.rules = {}
    this.orgId = this.$route.query.orgId || sessionStorage.getItem('listOrgId')
    //个人待办走的接口
    if (this.stepCode) {
      this.businessId = this.$route.query.businessId
      this.type = this.$route.query.type
      this.pmdUserId = this.$route.query.pmdUserId
      this.formCode = this.pmdUserType == 10 || this.pmdUserType == 20 ? 'FZDY0603' : 'FZDY2103'
      this.getBisFileList()
      this.getDynamicFormInfoSingle(this.formCode)
    }else{
      //模态框的步骤
      this.open(this.params)
    }
  },
  provide () {
    return {
      expandParent: this.expandParent
    }
  },
  props: ['params'],
  watch: {
    'params': {
      handler (val) {
        if (val.pmdUserType) {
          this.open(val)
        }
      },
      //immediate: true,
      deep: true
    }
  },
  methods: {
    open (params) {
      this.businessId = params.businessId
      this.type = params.type
      this.active = params.active
      this.pmdUserId = params.pmdUserId
      this.pmdUserType = params.pmdUserType
      this.formCode = this.pmdUserType == 10 || this.pmdUserType == 20 ? 'FZDY0603' : 'FZDY2103'
      this.formId = params.formId
      this.version = params.version
      // this.$methods('Modal', 'open')
      // this.showModal = true
      this.getBisFileList()
      this.getDynamicFormInfo()
    },
    // 附件模板
    downTemp (type, data) {
      this.$ajax({
        url: 'api-uum/module/pmd/person/getAttacheFile',
        method: 'GET',
        data: {
          attachFileConfigId: data.attachFileConfigId
        }
      }).then(({data}) => {
        let fileId = data.data.fileId
        let url = `api-uum/file/downloadFile?fileId=${fileId}` + '&access_token=' + sessionStorage.getItem('token') + '&currentAuthScopeId=' + sessionStorage.getItem('listOrgId')
        window.open(url)
      })
    },
    // 获取动态表单接口,modal
    getDynamicFormInfo () {
      this.$ajax({
        url: 'api-general/module/dynamicform/form/getFormStructure',
        method: 'get',
        data: {
          formId: this.formId,
          version: this.version
        }
      }).then(async ({ data }) => {
        this.layoutList = []
        let groupList = data.data.formGroupList || []
        await groupList.map(async (item, index) => {
          if (item.isEnabled == 1 && item.groupCode == 'page02') {
            this.layoutList = await this.generatePage(item)
          }
        })
        this.getPmdUser()
      })
    },
    //作为单独页面，生成动态表单
    getDynamicFormInfoSingle (formCode) {
      this.$ajax({
        url: 'api-uum/module/dynamic/form/getLastedFormStructure',
        method: 'GET',
        data: {
          domainCode: 'PMD',
          formCode: formCode
        }
      }).then(async ({ data }) => {
        this.layoutList = []
        let groupList = data.data.formGroupList || []
        await groupList.map(async (item, index) => {
          if (item.isEnabled == 1 && item.groupCode == 'page02') {
            //修改年份和季度为不可更改
            item.formPropertyList.forEach(ite => {
              if ( ite.displayName === '季度'){
                ite.isReadonly = 1
              }
              if ( ite.displayName === '年份'){
                ite.isReadonly = 1
              }
            })
            this.layoutList = await this.generatePage(item)
          }
        })
        this.getPmdUser()
      })
    },
    getBisFileList () {
      this.$ajax({
        url: this.formCode == 'FZDY0603' ? 'api-uum/module/pmd/person/getPmd06' : 'api-uum/module/pmd/person/getPmd21',
        method: 'GET',
        data: {
          pmdUserId: this.pmdUserId,
          orgId: sessionStorage.getItem('listOrgId')
        }
      }).then(({data}) => {
        this.uploadFileList = data.data.bisFileList || {}
      })
    },
    getPmdUser () {
      this.$ajax({
        url: 'api-uum/module/pmd/person/getPmdUser',
        method: 'GET',
        data: {
          userId: sessionStorage.getItem('userId')
        }
      }).then(({data}) => {
        this.formItem.pmdUserName = data.data.userName
        this.formItem.pmdUserCode = data.data.userCode
        this.formItem.cf1 = data.data.userName
        this.formItem.cf2 = data.data.userCode
        this.formItem.year = new Date()
        this.formItem.reportTime = timeFormat(new Date())
        let _time = this.formCode == 'FZDY0603' ? (this.$route.name == 'recruitingPartyMembers' || this.$route.query.stepCode == '06trainingEducationInspection' ? data.data.activistDate : data.data.applyTime) : data.data.joinPartyDate
        if (_time > new Date().getTime()) {
          this.formItem.reportTime = timeFormat(_time)
        }
        this.$nextTick(() => {
          setMinDate(['reportTime'], _time, this.$refs.ComputedFormLayout.layoutList)
        })
        if (this.businessId) {
          this.$nextTick(() => {
            this.getDetail()
          })
        }
      })
    },
    // 获取详情
    getDetail () {
      this.$ajax({
        url: 'api-uum/module/pmd/person/inspect/getPmdReport',
        method: 'GET',
        data: {
          type: this.type,
          businessId: this.businessId
        }
      }).then(({data}) => {
        this.formItem = data.data
        this.pmdUserId = data.data.pmdUserId
        this.formItem.quarter = this.formItem.quarter + ''
        this.formItem.pmdUserName = this.formItem.userName
        this.formItem.pmdUserCode = this.formItem.userCode
        this.formItem.cf1 = this.formItem.userName
        this.formItem.cf2 = this.formItem.userCode
        this.formItem.year = new Date(this.formItem.year + '')
        this.formItem.redactTime = timeFormat(this.formItem.redactTime)
        this.formItem.reportTime = timeFormat(this.formItem.reportTime)
        this.formItem.uploadTime = timeFormat(this.formItem.uploadTime)
        if (this.formCode == 'FZDY0603') {
          this.formItem['02FZDY0602'] = [{
            fileId: this.formItem.fileId,
            fileName: this.formItem.fileName
          }]
        } else {
          this.formItem['05FZDY2101'] = [{
            fileId: this.formItem.fileId,
            fileName: this.formItem.fileName
          }]
        }
      })
    },
    // 按钮
    onBtnEvents () {
      this.$refs.ComputedFormLayout.validateify().then(res => {
        if (!res) return
        this.$ajax({
          url: this.businessId ? 'api-uum/module/pmd/person/inspect/updPmdReport' : 'api-uum/module/pmd/person/inspect/addPmdReportData',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          disabledData: true,
          data: {
            orgId: sessionStorage.getItem('orgId'),
            ...this.formItem,
            year: timeFormat(this.formItem.year, 'yyyy'),
            redactTime: timeFormat(this.formItem.redactTime),
            reportTime: timeFormat(this.formItem.reportTime),
            uploadTime: timeFormat(this.formItem.uploadTime),
            pmdUserId: this.pmdUserId,
            pmdUserType: this.pmdUserType,
            //新加，要传参数
            reportId: this.$route.query.reportId,
            type: this.type,
            bisFileList: this.uploadFileList
          }
        }).then(({ data }) => {
          this.formItem = {}
          this.$Message.success('上传成功')
          // this.$methods('Modal', 'close')
          //this.$emit('on-refresh')
          if (!this.stepCode) {
            this.$emit('on-refresh')
          }
          else{
            this.close()
          }
        })
      })
    },
    //按钮取消保存
    close () {
      //this.$router.go(-1)
      this.$router.push({
        path: 'index'
      })
    },
    // 生成表单和表格
    generatePage (index) {
      const list = this.formCode == 'FZDY0603' ? generateList.call(this, index) : twentyFirstGenerateList.call(this, index)
      list.forEach(item => {
        if (item.type === 'InputNumber' || item.type === 'Number') {
          this.$set(this.formItem, item[$field], null)
        }
      })
      return list
    },
    // 表单附件上传
    uploadSuccess (file, key, index, code) {
      if (file.success) {
        if (!this.uploadFileList[key]) {
          this.uploadFileList[key] = []
        } else {
          let _index = this.uploadFileList[key].findIndex(_ => { return _.fileId == this.formItem['fileId'] })
          if (_index > -1) {
            this.uploadFileList[key][_index] = {
              fileName: file.response.data.fileName,
              fileId: file.response.data.fileId
            }
          } else {
            this.uploadFileList[key].push({
              fileName: file.response.data.fileName,
              fileId: file.response.data.fileId
            })
          }
        }
        this.formItem['fileId'] = file.response.data.fileId
        this.formItem['fileName'] = file.response.data.fileName
        this.$Message.success('上传成功')
      }
    },
    uploadClear (fileUpload, item, uploadFiles, key) {
      this.uploadFileList[key] && this.uploadFileList[key].map((file, index) => {
        if (file.fileId == item.fileId) {
          this.uploadFileList[key].splice(index, 1)
        }
      })
    }
  },
  components: {
    FormLayout,
    LayoutTitle,
    FooterOperator 
  }
}
</script>
<style lang="scss" scoped>
.iv-pseronal {
  min-height: 100%;;
  background-color: #fff;
}
</style>
<style lang="scss">
.iv-confirm-modal {
  .ivu-modal-mask{
    z-index: 3000!important;
  }
  .ivu-modal-wrap {
    z-index: 3000!important;
  }
}
</style>


